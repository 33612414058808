import React, { useEffect, useState } from 'react';
import BackButton from '../../components/BackButton';

import OrderSummaryContent from './OrderSummaryContent';
import { Button } from '../../components/Button';

import VerveImage from '../../assets/icons/card-logos/verve.png';
import MastercardImage from '../../assets/icons/card-logos/mastercard.png';
import VisaImage from '../../assets/icons/card-logos/visa.png';
import CartItemEntry from './CartItemEntry';
import { useHistory, useParams } from 'react-router-dom';
import { AppState } from '../../store/RootReducer';
import { CartActionType, CartItem } from '../../store/cart/types';
import { removeCartItem, setCartItemQuantity, setCartItems } from '../../store/cart/actions';
import { connect } from 'react-redux';

import EmptyCartView from './EmptyCartView';
import { triggerCreateCheckoutOrder } from '../../store/order/actions';
import { CreateCheckoutOrderRequest, OrderItemProps } from '../../repositories/OrderRepository';
import { OrderActionType } from '../../store/order/types';
import { StoreDetails } from '../../entities/StoreDetails';
import usePrevious from '../../hooks/usePrevious';
import { ShoppingCartViewActionType } from '../../store/ui/ShoppingCartView/types';
import { triggerFetchProductsDetails } from '../../store/ui/ShoppingCartView/actions';

import { GetProductsDetailsRequest } from '../../repositories/ProductRepository';
import LoadingPageView from '../LoadingPageView';
import { StoreProductDetails } from '../../entities/StoreProductDetails';

import {
  BackControlContainer,
  CheckoutButtonContainer,
  CheckoutItemContainer,
  CheckoutItemsContainer,
  Container,
  EmptyCartViewContainer,
  MainContainer,
  OrderDetailsContainer,
  OrderSummaryContainer,
  SupportedIssuersContainer
} from './style';
import ErrorView from './ErrorView';
import { ToastBoardActionType, TriggerDisplayToastPayload } from '../../store/ui/ToastBoard/types';
import { triggerDisplayToast } from '../../store/ui/ToastBoard/actions';
import { getPageTitlteSuffix } from '../util';
import translate from 'translations/utils';
import { FormattedMessage } from 'react-intl';

const supportedIssuerImages = [VerveImage, MastercardImage, VisaImage];
const multipaySupportedIssuers = [MastercardImage, VisaImage]


interface CartItemValidity {
  isAvailable: boolean;
  exceedsAvailableQuantity: boolean;
  availableQuantity: number;
}


interface ProductPriceChange {
  oldPrice: number;
  newPrice: number;
}

interface ProductPriceChangesMap {
  [key: string]: ProductPriceChange;
}

interface RouteParams {
  storeUrlEndpoint: string;
}

interface StoreStateProps {
  storeDetails: StoreDetails;

  cartInitialised: boolean;
  cartItems: CartItem[];

  fetchingProductsDetails: boolean;
  fetchingProductsDetailsError: boolean;
  productsDetails: StoreProductDetails[] | null;

  creatingOrder: boolean;
  creatingOrderError: boolean;
}

interface StoreDispatchProps {
  displayToast: (payload: TriggerDisplayToastPayload) => void;
  fetchProductsDetails: (request: GetProductsDetailsRequest) => void;
  setCartItems: (payload: { cartItems: CartItem[] }) => void;
  removeCartItem: (payload: { cartItemId: string }) => void;
  setCartItemQuantity: (payload: { cartItemId: string, quantity: number }) => void;
  createCheckoutOrder: (request: CreateCheckoutOrderRequest) => void;
}

interface OwnProps {

}

type Props = StoreStateProps & StoreDispatchProps & OwnProps;


function ShoppingCartView(props: Props) {
  const {
    storeDetails,

    cartInitialised,
    cartItems,
    setCartItems,

    fetchingProductsDetails,
    fetchingProductsDetailsError,
    productsDetails,

    creatingOrder,
    creatingOrderError,

    displayToast,
    fetchProductsDetails,
    removeCartItem,
    setCartItemQuantity,
    createCheckoutOrder
  } = props;

  const history = useHistory();
  const { name, whiteLabelAcquirer } = storeDetails;

  useEffect(() => {
    window.scrollTo(0, 0);

    document.title = `Cart - ${getPageTitlteSuffix(name)}`;
  }, []);

  const cartInitialisedPrev = usePrevious(cartInitialised);

  const fetchingProductsDetailsPrev = usePrevious(fetchingProductsDetails);

  const creatingOrderPrev = usePrevious(creatingOrder);

  const { storeUrlEndpoint } = useParams<RouteParams>();

  const [productsPriceChanges, setProductsPriceChanges] = useState<ProductPriceChangesMap>({});

  const [pageInitialized, setPageInitialized] = useState(false);


  const latestProductsDetails: { [key: number]: StoreProductDetails } = {};


  if (productsDetails) {
    productsDetails.forEach(productDetails => {
      latestProductsDetails[productDetails.id] = productDetails;
    });
  }


  const backButtonHandler = () => {
    history.push({ pathname: `/${storeUrlEndpoint}` });
  }

  const checkoutButtonHandler = () => {
    let totalAmount = 0;

    const orderItems: OrderItemProps[] = cartItems.map(cartItem => {
      const { quantity, productPrice, variantName, variantValue, productData } = cartItem;
      const { id } = productData;

      totalAmount += quantity * productPrice;

      return {
        productId: id,
        amount: productPrice * quantity,
        quantity: quantity,
        variantName: variantName,
        variantValue: variantValue
      }
    });

    createCheckoutOrder({
      storeId: storeDetails.id,
      amount: totalAmount,
      orderItems: orderItems
    });
  }

  const decrimentItemQuantity = (cartItemId: string, currentQuantity: number) => {
    if (currentQuantity - 1 < 1) return;

    setCartItemQuantity({ cartItemId, quantity: currentQuantity - 1 });
  }

  const incrementItemQuantity = (cartItemId: string, currentQuantity: number) => {
    setCartItemQuantity({ cartItemId, quantity: currentQuantity + 1 });
  }

  const removeItem = (cartItemId: string) => {
    removeCartItem({ cartItemId });
  }


  const initiateFetchProductsDetails = () => {
    const productIds: number[] = [];

    if (cartItems.length === 0) {
      setPageInitialized(true);
      return;
    };

    cartItems.forEach(cartItem => {
      const { productData } = cartItem;
      productIds.push(productData.id);
    });

    fetchProductsDetails({
      merchantCode: storeDetails.merchantCode,
      storeId: storeDetails.id,
      productIds: productIds
    });
  }

  const updateCartPriceChanges = () => {

    const productsPriceChanges: ProductPriceChangesMap = {};

    const updatedCartItems = cartItems.map(cartItem => {

      const { productData } = cartItem;

      const latestProductDetails = latestProductsDetails[productData.id];

      // check if latest product details exist
      if (!latestProductDetails) {
        return cartItem;
      };

      let latestProductPrice = latestProductDetails.price;

      // check of cart item is a product variant
      if (cartItem.variantName && cartItem.variantValue) {
        const { productVariants } = latestProductDetails;

        const productVariant = productVariants.find(variant => {
          return variant.variantName === cartItem.variantName &&
            variant.variantValue === cartItem.variantValue;
        });

        if (!productVariant) {
          return {
            ...cartItem,
            productData: {
              ...cartItem.productData,
              name: latestProductDetails.name
            }
          }
        };

        latestProductPrice = productVariant.price;
      }

      if (cartItem.productPrice !== latestProductPrice) {
        productsPriceChanges[productData.id] = {
          oldPrice: cartItem.productPrice,
          newPrice: latestProductPrice
        };
      }

      return {
        ...cartItem,
        productPrice: latestProductPrice,
        productData: {
          ...cartItem.productData,
          name: latestProductDetails.name
        }
      };
    });

    setCartItems({ cartItems: updatedCartItems });

    setProductsPriceChanges(productsPriceChanges);
  };

  // Fetch cart item details if cart has been initialized on cart page load
  useEffect(() => {
    if (!cartInitialised) return;
    initiateFetchProductsDetails();
  }, []);


  // Fetch cart item details when cart has been initialized
  useEffect(() => {
    if (!cartInitialisedPrev && cartInitialised) {
      initiateFetchProductsDetails();
    }
  }, [cartInitialised]);

  useEffect(() => {
    if (fetchingProductsDetailsPrev && !fetchingProductsDetails) {
      setPageInitialized(true);
    }

    if (fetchingProductsDetailsPrev && !fetchingProductsDetails && !fetchingProductsDetailsError) {
      updateCartPriceChanges();
    }
  }, [fetchingProductsDetails]);

  // Navigate to checkout page when order has been created
  useEffect(() => {
    if (creatingOrderPrev && !creatingOrder && !creatingOrderError) {
      history.push({ pathname: `/${storeUrlEndpoint}/checkout` });
    }
  }, [creatingOrder]);

  useEffect(() => {
    if (creatingOrderError) {
      initiateFetchProductsDetails();
      displayToast({ toastType: "ERROR" });
    }
  }, [creatingOrderError]);


  const getCartItemValidity = (
    latestProductDetails: StoreProductDetails | null, cartItem: CartItem): CartItemValidity => {
    const cartItemValidity: CartItemValidity = {
      isAvailable: true,
      exceedsAvailableQuantity: false,
      availableQuantity: 0,
    };

    // check if item is still available from the store's listing
    if (!latestProductDetails) {
      cartItemValidity.isAvailable = false;
      return cartItemValidity;
    }

    const { productVariants } = latestProductDetails;

    const productVariant = productVariants.find(variant => {
      return variant.variantName === cartItem.variantName &&
        variant.variantValue === cartItem.variantValue;
    });

    // check if product variant is still available
    if (cartItem.variantName && cartItem.variantValue && !productVariant) {
      cartItemValidity.isAvailable = false;
      return cartItemValidity;
    }

    // get current product available quantity
    let availableProductQuantity = productVariant ?
      productVariant.quantity : latestProductDetails.quantity;

    // check if cart quantity exceeds current available quantity
    if (cartItem.quantity > availableProductQuantity) {
      cartItemValidity.exceedsAvailableQuantity = true;
      cartItemValidity.availableQuantity = availableProductQuantity;
      return cartItemValidity;
    }

    return cartItemValidity;
  };


  // check if any item in the cart is not valid
  let isCartValid = true;

  cartItems.forEach(cartItem => {
    const { productData } = cartItem;

    const latestProductDetails = latestProductsDetails[productData.id];

    const cartItemvalidity = getCartItemValidity(
      latestProductDetails, cartItem
    );

    const { isAvailable, exceedsAvailableQuantity } = cartItemvalidity;

    if (!isAvailable || exceedsAvailableQuantity) {
      isCartValid = false;
    }
  });

  return (
    <Container>

      <BackControlContainer>
        <BackButton
          text={translate('continueShopping', 'Continue shopping')}
          onClick={backButtonHandler}
        />
      </BackControlContainer>


      {fetchingProductsDetails && (
        <LoadingPageView />
      )}


      {!fetchingProductsDetails && fetchingProductsDetailsError && (
        <ErrorView
          actionHandler={initiateFetchProductsDetails}
        />
      )}


      {pageInitialized && !fetchingProductsDetails && !fetchingProductsDetailsError && cartItems.length === 0 && (
        <EmptyCartViewContainer>
          <EmptyCartView />
        </EmptyCartViewContainer>
      )}


      {pageInitialized && !fetchingProductsDetails && !fetchingProductsDetailsError && cartItems.length > 0 && (
        <MainContainer>

          <CheckoutItemsContainer>
            {cartItems.map(cartItem => {
              const { id, productData, quantity, currencyCode, productPrice, variantName, variantValue } = cartItem;

              const { name, productImageUrl, productUrlEndpoint, id: productId } = productData;

              const urlEndpoint = `/${storeUrlEndpoint}/product/${productUrlEndpoint}`;

              const latestProductDetails = latestProductsDetails[productId];

              const cartItemValidity = getCartItemValidity(latestProductDetails, cartItem);

              const productPriceChanges = productsPriceChanges[productId];

              return (
                <CheckoutItemContainer>
                  <CartItemEntry
                    key={id}

                    isAvailable={cartItemValidity.isAvailable}
                    exceedsAvailableQuantity={cartItemValidity.exceedsAvailableQuantity}
                    availableQuantity={cartItemValidity.availableQuantity}
                    priceChanged={!!productPriceChanges}
                    previousPrice={productPriceChanges ? productPriceChanges.oldPrice : 0}

                    productUrlEndpoint={urlEndpoint}
                    name={name}
                    price={productPrice}
                    currencyCode={currencyCode}
                    productImageUrl={productImageUrl}
                    quantity={quantity}
                    variantName={variantName}
                    variantValue={variantValue}

                    decrementQuantityHandler={() => decrimentItemQuantity(id, quantity)}
                    incrementQuantityHandler={() => incrementItemQuantity(id, quantity)}
                    removeHandler={() => removeItem(id)}
                  />
                </CheckoutItemContainer>
              );
            })}
          </CheckoutItemsContainer>

          <OrderDetailsContainer>

            <OrderSummaryContainer>
              <OrderSummaryContent cartItems={cartItems} />
            </OrderSummaryContainer>

            <CheckoutButtonContainer>
              <Button
                text={<FormattedMessage id="continueToCheckout" defaultMessage="Continue to Checkout" />}
                color="PRIMARY"
                onClick={checkoutButtonHandler}
                loading={creatingOrder}
                disabled={!isCartValid}
                containerStyle={{ width: '100%' }}
              />
            </CheckoutButtonContainer>

            <SupportedIssuersContainer>
              <p>
                <FormattedMessage id="weAccept" defaultMessage="We accept:" />
              </p>
              {
                whiteLabelAcquirer?.acquiredBy === 'MUL' ? (
                  multipaySupportedIssuers.map(imageSrc => {
                    return < img src={imageSrc} alt="Issuer Images" key={imageSrc} />
                  })
                ) : (
                  supportedIssuerImages.map(imageSrc => {
                    return < img src={imageSrc} alt="Issuer Images" key={imageSrc} />
                  })
                )
              }
            </SupportedIssuersContainer>

          </OrderDetailsContainer>

        </MainContainer>
      )}


    </Container>
  );
}

const mapStateToProps = (state: AppState): StoreStateProps => ({
  storeDetails: state.store.storeDetails as StoreDetails,

  cartInitialised: state.cart.cartInitialised,
  cartItems: state.cart.cartItems,

  fetchingProductsDetails: state.ui.shoppingCartView.fetchingProductsDetails,
  fetchingProductsDetailsError: state.ui.shoppingCartView.fetchingProductsDetailsError,
  productsDetails: state.ui.shoppingCartView.productsDetails,

  creatingOrder: state.order.creatingOrder,
  creatingOrderError: state.order.creatingOrderError,
});

const mapDispatchToProps = (dispath: (action: ToastBoardActionType | ShoppingCartViewActionType | CartActionType | OrderActionType) => void): StoreDispatchProps => ({
  displayToast(payload: TriggerDisplayToastPayload) {
    dispath(triggerDisplayToast(payload));
  },
  fetchProductsDetails(request: GetProductsDetailsRequest) {
    dispath(triggerFetchProductsDetails(request));
  },
  setCartItems(payload: { cartItems: CartItem[] }) {
    const { cartItems } = payload;
    dispath(setCartItems({ cartItems }));
  },
  setCartItemQuantity(payload: { cartItemId: string, quantity: number }) {
    dispath(setCartItemQuantity(payload));
  },
  removeCartItem(payload: { cartItemId: string }) {
    dispath(removeCartItem(payload));
  },
  createCheckoutOrder(request: CreateCheckoutOrderRequest) {
    dispath(triggerCreateCheckoutOrder(request));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCartView);
