import React, {useState} from 'react';

import AppLogo from '../../assets/images/app-logo.png';
import { FormattedMessage } from 'react-intl';

import {
  Container,
  ContentContainer,
  InitialsContainer,
  LogoContainer,
  DetailsContainer,
  TitleContainer,
  ProviderContainer,
  ControlsContainer,
  ActionControl,
  TranslationSwitch
} from './style';

import ShoppingCartWidget from '../ShoppingCartWidget';
import { AppState } from '../../store/RootReducer';
import { connect } from 'react-redux';
import { StoreDetails } from '../../entities/StoreDetails';
import { useHistory } from 'react-router-dom';
import { TranslationContext } from 'contexts/TranslationContext';
import { changeLanguage } from 'translations/utils';
import { EnvironmentControl } from 'components/Switch/style';
import SwitchControl from 'components/Switch';


interface StoreStateProps {
  storeDetails: StoreDetails;
}


interface OwnProps {

}

type Props = StoreStateProps & OwnProps;



function StoreHeader(props: Props) {
  const { storeDetails } = props;

  const history = useHistory();

  const navigateToStoreHome = () => {
    const { urlEndpoint } = storeDetails;
    history.push({ pathname: `/${urlEndpoint}` });
  }


  const { name, initials, whiteLabelAcquirer } = storeDetails;

  const { setLang } = React.useContext(TranslationContext);
  
    const [checked, setChecked] = useState(false);
  
    const handleChange = () => {
      if(checked) {
        setChecked(false);
        setLang('en');
        changeLanguage('en');
      } else {
        setChecked(true);
        setLang('fr');
        changeLanguage('fr');
      }
    };

  return (
    <Container>

      <ContentContainer>
        {/* <LogoContainer>
          <img src={DummyImage} />
        </LogoContainer> */}

        <InitialsContainer  style={whiteLabelAcquirer && { backgroundColor: whiteLabelAcquirer.primaryColor }}>
          <p>{initials}</p>
        </InitialsContainer>

        <DetailsContainer>

          <TitleContainer onClick={navigateToStoreHome}>
            <h2 style={whiteLabelAcquirer && { color: whiteLabelAcquirer.primaryColor }}>{name}</h2>
          </TitleContainer>

          <ProviderContainer>
            <p style={whiteLabelAcquirer && { color: whiteLabelAcquirer.secondaryColor }}><FormattedMessage id='poweredBy' defaultMessage="powered by " /> <b>{whiteLabelAcquirer?.shortName || 'Interswitch'}</b></p>
            <img src={AppLogo} />
          </ProviderContainer>

        </DetailsContainer>

        <ControlsContainer>
          <ActionControl>
            <ShoppingCartWidget />
          </ActionControl>

        </ControlsContainer>
        {
          whiteLabelAcquirer.acquiredBy === 'MUL' && (
            <TranslationSwitch>
        <EnvironmentControl>
          <div className='translationButtonWrapper'>
          <span className='en-span'>EN</span>
          <SwitchControl active={checked} activeColor='#2EB57E' onClickHandler={handleChange} />
          <span className='fr-span'>FR</span>
          </div>
        </EnvironmentControl>
        </TranslationSwitch>
          )
        }
      </ContentContainer>

    </Container>
  );
}

const mapStateToProps = (state: AppState): StoreStateProps => ({
  storeDetails: state.store.storeDetails as StoreDetails
});


export default connect(mapStateToProps, {})(StoreHeader);