import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { CARD_BORDER_COLOR, CARD_CONTENT_PADDING } from './style';
import { getFormattedPrice } from '../../util/currencyUtil';
import { OrderStatusOrderItem } from '../../repositories/OrderRepository';
import { FormattedMessage } from 'react-intl';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid ${CARD_BORDER_COLOR};
`;


const HeaderContainer = styled.div`
  padding: 15px ${CARD_CONTENT_PADDING}px;
  border-bottom: 1px solid ${CARD_BORDER_COLOR};

  h6 {
    font-size: 1.6rem;
  }
`;

const DataContainer = styled.div`
  flex: 1;
  padding: ${CARD_CONTENT_PADDING}px;
`;

const DataRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 15px ${CARD_CONTENT_PADDING}px;
  border-top: 1px solid ${CARD_BORDER_COLOR};

  p {
    font-weight: bold;
    font-size: 1.6rem;
  }
`;

interface Props {
  orderItems: OrderStatusOrderItem[];
  currencyCode: string;
  deliveryFee: number;
}

export default function PriceBreakdownContent(props: Props) {
  const { orderItems, currencyCode, deliveryFee } = props;

  let subtotalAmount = 0;

  orderItems.forEach(orderItem => {
    const { amount } = orderItem;

    subtotalAmount += amount;
  });


  const totalAmount = subtotalAmount + deliveryFee;

  return (
    <Container>

      <HeaderContainer>
        <h6>
          <FormattedMessage id="subtotal" defaultMessage="Subtotal" />
        </h6>
      </HeaderContainer>


      <DataContainer>
        <DataRow>
          <p>
            <FormattedMessage id="subtotal" defaultMessage="Subtotal" />
          </p>
          <p>{getFormattedPrice(subtotalAmount, currencyCode)}</p>
        </DataRow>

        <DataRow>
          <p>
            <FormattedMessage id="shipping" defaultMessage="Shipping" />
          </p>

          {deliveryFee == 0 && (
            <p>Free</p>
          )}

          {deliveryFee > 0 && (
            <p>{getFormattedPrice(deliveryFee, currencyCode)}</p>
          )}
        </DataRow>

      </DataContainer>

      <FooterContainer>
        <p>
          <FormattedMessage id="total" defaultMessage="Total" />
        </p>
        <p>{getFormattedPrice(totalAmount, currencyCode)}</p>
      </FooterContainer>

    </Container>
  );
}