import Dinero from 'dinero.js';

const currencyStringValues: { [key: string]: string } = {
  '566': 'NGN ',
  '404': 'KES',
  '800': 'UGX',
  '840': 'USD',
  '976': 'CDF'
};

export const getCurrencyString = (currencyCode: string) => {
  if (!currencyStringValues[currencyCode]) return '';

  return currencyStringValues[currencyCode];
}

export const getMajorAmountString = (minorAmount: number) => {
  const amount = Dinero({ amount: minorAmount, precision: 2 });
  return amount.toFormat('0,0.00');
}

export const getFormattedPrice = (minorAmount: number, currencyCode: string) => {
  const currencyString = getCurrencyString(currencyCode);
  const majorAmountString = getMajorAmountString(minorAmount);

  return `${currencyString} ${majorAmountString}`;
}