import React, { useState } from 'react';
import styled, { css } from "styled-components";

import RadialButton from '../../../../components/RadialButton';
import { CustomerAddress } from '../../../../repositories/CustomerRepository';
import { ColorTheme } from '../../../../styling/constants';
import { FormattedMessage } from 'react-intl';

const Contaier = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 25px;
  border-bottom: 1px solid #dadada;
  padding-bottom: 25px;

  &:last-child {
    margin-bottom: 0px;
    border-bottom: none;
  }
`;

const RadialButtonContainer = styled.div`
  margin-right: 20px;
  margin-top: 5px;
`;

const AddressDetailsContent = styled.div``;

const NameContainer = styled.div`
  margin-bottom: 10px;

  p {
    color: ${ColorTheme.primaryColorDark};
  }
`;

const AddressContainer = styled.div`
  margin-bottom: 10px;
`;

const PhoneNumberContainer = styled.div`
  margin-bottom: 20px;
`;

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

interface ActionButtonProps {
  loading: boolean;
}

const ActionButton = styled.div<ActionButtonProps>`
  cursor: pointer;

  ${({ loading }) => loading && css`
    opacity: 0.4;
  `};

  p {
    font-size: 1.4rem;
  }
`;

interface Props {
  customerAddress: CustomerAddress;
  selected: boolean;
  deleting: boolean;
  onSelect: (addressId: number) => void;
  onDelete: () => void;
}

export default function AddressEntry(props: Props) {
  const { customerAddress, selected, deleting, onSelect, onDelete } = props;

  let addressText = '';
  if (customerAddress) {
    const { country, state, area, address } = customerAddress;

    addressText += `${address},`;

    if (area) addressText += ` ${area},`;
    if (state) addressText += ` ${state},`;

    addressText += country;
  }

  return (
    <Contaier>
      <RadialButtonContainer>
        <RadialButton
          active={selected}
          onClick={() => onSelect(customerAddress.id)}
        />
      </RadialButtonContainer>

      <AddressDetailsContent>
        <NameContainer>
          <p>{customerAddress.customerName}</p>
        </NameContainer>

        <AddressContainer>
          <p>{addressText}</p>
        </AddressContainer>

        <PhoneNumberContainer>
          <p>{customerAddress.customerPhoneNumber}</p>
        </PhoneNumberContainer>

        <ControlsContainer>
          <ActionButton loading={deleting} onClick={onDelete}>
            <p style={{ color: 'red' }}>
              <FormattedMessage id="delete" defaultMessage="Delete" />
            </p>
          </ActionButton>
        </ControlsContainer>

      </AddressDetailsContent>
    </Contaier>
  );
}