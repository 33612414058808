import React from 'react';
import styled from "styled-components";
import { StoreProduct } from "../../entities/StoreProduct";
import AsyncImage from '../../components/AsyncImage';
import { SkeletonBox, SkeletonText } from '../../components/Skeleton';
import { Link } from 'react-router-dom';
import { ScreenSize, ColorTheme } from '../../styling/constants';
import { RECOMMENDED_ITEMS_COUNT } from './style';
import { getFormattedPrice } from '../../util/currencyUtil';
import { FormattedMessage } from 'react-intl';


const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 50px;

  h6 {
    font-size: 1.6rem;
  }
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;

  @media (min-width: ${ScreenSize.XS}px) {
    padding-bottom: 30px;
  }
`;


const ProductItem = styled.div`
  width: calc(${100 / RECOMMENDED_ITEMS_COUNT}% - ${(20 * RECOMMENDED_ITEMS_COUNT - 20) / RECOMMENDED_ITEMS_COUNT}px);
  min-width: 200px;
  margin-right: 20px;
  flex-shrink: 0;

  &:last-child {
    margin-right: 0px;
  }
`;

const ProductImageContainer = styled(Link)`
  height: 240px;
  display: block;
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
`;


const ProductNameContainer = styled.div`
  margin-bottom: 10px;

  a {
    cursor: pointer;
    color: ${ColorTheme.primaryColorLight};
    font-size: 1.6rem;
    font-weight: 100;
  }
`;

const ProductPriceContainer = styled.div`
  p {
    color: black;
  }
`;

interface Props {
  storeUrlEndpoint: string;
  loading: boolean;
  errorLoading: boolean;
  products: StoreProduct[];
}

export default function RecommendedProductsContent(props: Props) {
  const { storeUrlEndpoint, loading, errorLoading, products } = props;

  const formatProductName = (name: string) => {
    if (name.length <= 40) return name;

    return `${name.slice(0, 40)}...`;
  }

  return (
    <Container>
      <TitleContainer>
        <h6>
          <FormattedMessage id="peopleAlsoBought" defaultMessage="People Also Bought" />
        </h6>
      </TitleContainer>


      {!errorLoading && (
        <ItemsContainer>
          {loading && Array.from({ length: RECOMMENDED_ITEMS_COUNT }).map(_ => {
            return (
              <ProductItem>

                <ProductImageContainer to="#">
                  <SkeletonBox />
                </ProductImageContainer>

                <ProductNameContainer>
                  <Link to="#"><SkeletonText style={{ width: '140px' }} /></Link>
                </ProductNameContainer>

                <ProductPriceContainer>
                  <p><SkeletonText style={{ width: '85px' }} /></p>
                </ProductPriceContainer>

              </ProductItem>
            );
          })}

          {!loading && products.map(product => {

            const { urlEndpoint, imageFileUrl, name, price, currencyCode } = product;

            const productUrlEndpoint = `/${storeUrlEndpoint}/product/${urlEndpoint}`;

            return (
              <ProductItem>

                <ProductImageContainer to={productUrlEndpoint || '#'}>
                  <AsyncImage
                    src={imageFileUrl}
                    alt="Product Image"
                    imageStyle={{ objectFit: 'contain' }}
                  />
                </ProductImageContainer>

                <ProductNameContainer>
                  <Link to={productUrlEndpoint || '#'}>{formatProductName(name)}</Link>
                </ProductNameContainer>


                <ProductPriceContainer>
                  <p>
                    {getFormattedPrice(price, currencyCode)}
                  </p>
                </ProductPriceContainer>

              </ProductItem>
            );
          })}
        </ItemsContainer>
      )}


    </Container>
  );
}