import styled from "styled-components";

const marineBlue = '#003c58';
const darkBlue = '#252b33';
const lightBlue = '#009fe4';
const dark15 = 'rgba(37, 43, 51, 0.15)'


export const SwitchControlWrapper = styled.div`
  display: inline-block;
  width: 36px;
  height: 18px;
  cursor: pointer;
  font-size: 1.8rem;

  .track {
    height: 100%;
    background-color: ${darkBlue};
    border-radius: 100px;
    box-shadow: 0 1px 4px 0 rgba(70, 70, 70, 0.06);
    padding: 2px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: background-color 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &.active .track {
    background-color: ${lightBlue};
    transition: background-color 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
   }

  .circle {
    width: calc(50% - 2px);
    height: 100%;
    border-radius: 100%;
    background-color: #ffffff;
    transition: transform 0.3s;
  }

   &.active .circle {
      transform: translateX(120%);
   }
`;

export const EnvironmentControl = styled.div`
  margin-left: 7px;

  .translationButtonWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 35px;
    border: 1px solid ${dark15};
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
  }

  .en-span {
    margin-right: 15px;
  }

  .fr-span {
    margin-left: 15px;
  }

  .mode-text {
    margin-right: 20px;
    color: #f05050;
    font-size: 1.3rem;
    font-weight: bold;

    &.live {
      color: #2eb57e;
    }
  }
`;

// .switch-control {
//   display: inline-block;
//   width: 36px;
//   height: 18px;
//   cursor: pointer;
//   font-size: 1.8rem;

//   .track {
//     height: 100%;
//     background-color: $dark;
//     border-radius: 100px;
//     box-shadow: 0 1px 4px 0 rgba(70, 70, 70, 0.06);
//     padding: 2px;
//     box-sizing: border-box;
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     transition: background-color 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
//   }

//   &.active .track {
//     background-color: $azure;
//     transition: background-color 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
//   }

//   .circle {
//     width: calc(50% - 2px);
//     height: 100%;
//     border-radius: 100%;
//     background-color: #ffffff;
//     transition: transform 0.3s;
//   }

//   &.active .circle {
//     // background-color: #50f097;
//     transform: translateX(120%);
//   }
// }