import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from "styled-components";
import { DeliveryAddressFormState, initialDeliveryAddressFormState } from '../..';
import BackButton from '../../../../components/BackButton';
import { Button } from '../../../../components/Button';
import NewAddressForm from '../NewAddressForm';
import translate from 'translations/utils';

const Contaier = styled.div``;

const BackControlContainer = styled.div`
  margin-bottom: 30px;
`;

const FormContainer = styled.div``;

interface Props {
  creatingAddress: boolean;
  submitButtonHandler: (formState: DeliveryAddressFormState) => void;
  cancelButtonHandler: () => void;
}

export default function NewAddressContent(props: Props) {
  const { creatingAddress, submitButtonHandler, cancelButtonHandler } = props;

  const [showFormErrors, setShowFormErrors] = useState(false);

  const [formState, setFormState] = useState(initialDeliveryAddressFormState);


  const onFormSubmitHandler = () => {
    setShowFormErrors(true);

    const {
      country, state, city, address, fullName, phoneNumber
    } = formState;

    if (!country || !state || !city || !address || !fullName || !phoneNumber) return;

    submitButtonHandler(formState);
  };

  return (
    <Contaier>
      <BackControlContainer>
        <BackButton
          text={translate('cancel', 'Cancel')}
          onClick={cancelButtonHandler}
        />
      </BackControlContainer>

      <FormContainer>
        <NewAddressForm
          showFormErrors={showFormErrors}
          emailEnabled={false}
          onFormChange={(formState) => setFormState(formState)}
        />
      </FormContainer>

      <Button
        text={<FormattedMessage id="saveAddress" defaultMessage="Save Address" />}
        color="PRIMARY"
        loading={creatingAddress}
        onClick={onFormSubmitHandler}
        containerStyle={{ width: '100%' }}
      />

    </Contaier>
  );
}