import React, { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as EmptyIcon } from '../../assets/icons/empty-product-list.svg';
import { ColorTheme } from '../../styling/constants';
import { FormattedMessage } from 'react-intl';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const IconContainer = styled.div`
  margin-bottom: 20px;

  svg {
    width: 35px;
    height: 35px;
    fill: ${ColorTheme.primaryColorDark};
  }
`;

const TitleContainer = styled.div`
  margin-bottom: 10px;

  h4 {
    font-size: 1.8rem;  
    font-weight: 100; 
    color: ${ColorTheme.primaryColorDark};
  }
`;

const MessageContainer = styled.div`
  p {
    font-size: 1.4rem;   
    text-align: center;
  }
`;

export default function EmptyStoreView() {
  return (
    <Container>

      <IconContainer>
        <EmptyIcon />
      </IconContainer>

      <TitleContainer>
        <h4>
          <FormattedMessage id="noProductsFound" defaultMessage="No Products Found" />
        </h4>
      </TitleContainer>

      <MessageContainer>
        <p>
          <FormattedMessage id="noProductForStorefront" defaultMessage="Couldn’t find any product for this storefront" />
        </p>
      </MessageContainer>

    </Container>
  );
}