import { WhiteLabelAcquirerConfigResponse } from 'entities/StoreDetails';
import React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'store/RootReducer';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { ScreenSize } from '../../../styling/constants';
import translate from 'translations/utils';

const Container = styled.div`
`;



const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  & div {
    margin-bottom: 15px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (min-width: ${ScreenSize.XS}px) {
    flex-direction: row;
    
    div {
      flex: 1;
      margin-right: 5%;
      margin-bottom: 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

interface Props {
  whiteLabelAcquirer?: WhiteLabelAcquirerConfigResponse;
  loginButtonHandler: () => void;
  continueAsGuestButtonHandler: () => void;
}

function EditContent(props: Props) {
  const {
    whiteLabelAcquirer,
    loginButtonHandler,
    continueAsGuestButtonHandler
  } = props;

  return (
    <Container>
      <ButtonsContainer>
        {
          !whiteLabelAcquirer &&
          <Button
            text={translate('checkoutWithQuickteller', 'Checkout with Quickteller')}
            color="PRIMARY"
            onClick={loginButtonHandler}
            containerStyle={{ width: '100%' }}
          />
        }

        <Button
          text={`${translate('checkout', 'Checkout')} ${whiteLabelAcquirer ? '' : ' as Guest'}`}
          color="PRIMARY"
          type="OUTLINE"
          onClick={continueAsGuestButtonHandler}
          containerStyle={{ width: '100%' }}
        />
      </ButtonsContainer>
    </Container>
  );
}

const mapStateToProps = (state: AppState) => ({
  whiteLabelAcquirer: state.store.storeDetails?.whiteLabelAcquirer
});

export default connect(mapStateToProps)(EditContent);