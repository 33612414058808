import React, { CSSProperties } from 'react';
import { SwitchControlWrapper } from './style';

interface Props {
  active: boolean;
  onClickHandler: (e: any) => void;
  passiveColor?: string;
  activeColor?: string;
  containerStyle?: CSSProperties;
}

export default function SwitchControl(props: Readonly<Props>) {
  const {
    active, onClickHandler, passiveColor, activeColor, containerStyle
  } = props;

  const trackColor = active ? activeColor : passiveColor;

  return (
    <SwitchControlWrapper
      className={`switch-control ${active ? 'active' : ''}`}
      onClick={onClickHandler}
      style={containerStyle}
    >
      <div className="track" style={{ backgroundColor: trackColor }}>
        <div className="circle" />
      </div>

    </SwitchControlWrapper>
  );
}