import * as React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { ScreenSize } from "styling/constants";

interface Props {
  header: React.ReactNode;
}
export default function BundleLayout(props: React.PropsWithChildren<Props>) {
  const { header, children } = props;

  return (
    <LayoutContainer>
      <HeaderSection>{header}</HeaderSection>
      <BundlesSection>
        <h4>
          <FormattedMessage id="bundleIncludes" defaultMessage="Bundle includes:" />
        </h4>
        {children}
      </BundlesSection>
    </LayoutContainer>
  );
}

const LayoutContainer = styled.div`
  width: 100%;
  &,
  * {
    box-sizing: border-box;
  }
`;

const HeaderSection = styled.header`
  width: 100%;
  padding: 59px 0px 39px;
  border-bottom: 1px solid rgba(0, 60, 88, 0.2);

  @media screen and (max-width: ${ScreenSize.XS}px) {
    padding: 59px 24px 39px;
  }
`;

const BundlesSection = styled.section`
  padding: 63px 0px;
  width: 100%;
  h4 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 48px;
    color: #003c58;
  }

  @media screen and (max-width: ${ScreenSize.XS}px) {
    padding: 63px 24px;
  }
`;
