import React, { useState } from 'react';
import { ColorTheme } from '../../styling/constants';
import { SummaryCard, CardRow, LabelValue } from '../SummaryCard';
import { CartItem } from '../../store/cart/types';
import { getFormattedPrice } from '../../util/currencyUtil';
import { FormattedMessage } from 'react-intl';

interface Props {
  cartItems: CartItem[];
}

export default function OrderSummaryContent(props: Props) {
  const { cartItems } = props;

  let priceTotal = 0;
  let itemsTotal = 0;

  cartItems.map(cartItem => {
    const { quantity, productPrice } = cartItem;

    priceTotal += productPrice * quantity;
    itemsTotal += quantity;
  });

  const { currencyCode } = cartItems[0];

  return (
    <SummaryCard>

      <CardRow>
        <LabelValue>
          <h6>
            <FormattedMessage id="orderSummary" defaultMessage="Order Summary" />
          </h6>
          <h6>{itemsTotal} <FormattedMessage id="items" defaultMessage="Item(s)" /></h6>
        </LabelValue>
      </CardRow>

      <CardRow>
        <LabelValue>
          <p>
            <FormattedMessage id="subtotal" defaultMessage="Subtotal" />
          </p>
          <p>{getFormattedPrice(priceTotal, currencyCode)}</p>
        </LabelValue>
      </CardRow>

      <CardRow>
        <LabelValue>
          <p>
            <FormattedMessage id="deliveryCharges" defaultMessage="Delivery Charges:" />
          </p>
          <p>
            <FormattedMessage id="deliveryChargesDesc" defaultMessage="Add your delivery address at checkout to see delivery charges" />
          </p>
        </LabelValue>
      </CardRow>

      <CardRow>
        <LabelValue>
          <h4>
            <FormattedMessage id="total" defaultMessage="Total" />
          </h4>
          <h4>{getFormattedPrice(priceTotal, currencyCode)}</h4>
        </LabelValue>
      </CardRow>

    </SummaryCard>
  );
}