import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

interface Props {
  isGuestCheckout: boolean;
  customerEmail?: string;
  editControlHandler: () => void;
}

const Container = styled.div``;

export default function CompletedContent(props: Props) {
  const { isGuestCheckout, customerEmail, editControlHandler } = props
  return (
    <Container>
      {isGuestCheckout && (
        <p><FormattedMessage id="proceedAsGuest" defaultMessage="Proceeding as Guest." /> {' '} <a href="#" onClick={editControlHandler}><FormattedMessage id="change" defaultMessage="Change" /></a></p>
      )}

      {!isGuestCheckout && (
        <p><FormattedMessage id="signedInAs" defaultMessage="Signed in as" /> {customerEmail}. <a href="#" onClick={editControlHandler}><FormattedMessage id="signOut" defaultMessage="Sign out" /></a></p>
      )}
    </Container>
  );
}