import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SelectDropdown from "../../../components/form-controls/SelectDropdown";
import { ColorTheme } from "../../../styling/constants";
import DeliveryOption from "./DeliveryOption";

import { getFormattedPrice } from "../../../util/currencyUtil";
import ShippingOptionItem from "./ShippingOptionItem";
import { Button } from "../../../components/Button";
import {
  DeliveryMethodsData,
  DeliveryMethod,
  ShippingOption,
} from "../../../repositories/OrderRepository";
import ShippingOptionValueContent from "./ShippingOptionValueContent";
import { DeliveryAddressFormState } from "..";
import { FormattedMessage } from "react-intl";
import translate from "translations/utils";

const Container = styled.div``;

const TitleMessageContainer = styled.div`
  margin-bottom: 30px;
`;

const DeliveryOptionContainer = styled.div`
  margin-bottom: 30px;
`;

const StoreAddressContainer = styled.div`
  padding: 20px;
  background-color: #fbfbfb;
  border: 1px solid #cbcbcb;
  border-radius: 4px;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  p:last-child {
    font-weight: bold;
    color: ${ColorTheme.primaryColorDark};
  }

  p {
    font-size: 1.4rem;
  }
`;

const SubmitControlContainer = styled.div``;

type DeliveryTypeOption = "PICKUP" | "STORE_SHIPPING" | "THIRD_PARTY_SHIPPING";

interface Props {
  deliveryMethodsData: DeliveryMethodsData;
  submitInProgress: boolean;
  onShippingFeeChange: (amount: number | null) => void;
  onSubmitHandler: (
    deliveryMethod: DeliveryMethod,
    shippingOptionId: string | null
  ) => void;
  navigateToPreviousStep: () => void;
  allDigitalItems?: boolean;
  formState: DeliveryAddressFormState;
}

export default function DeliveryMethodForm(props: Props) {
  const {
    deliveryMethodsData,
    submitInProgress,
    onShippingFeeChange,
    onSubmitHandler,
    navigateToPreviousStep,
    allDigitalItems,
    formState,
  } = props;

  const {
    pickupOption,
    merchantShippingOptions,
    thirdPartyShippingOptions,
    currencyCode,
  } = deliveryMethodsData;

  const [
    deliveryTypeOption,
    setDeliveryTypeOption,
  ] = useState<DeliveryTypeOption | null>(null);

  const [deliveryMethod, setDeliveryMethod] = useState<DeliveryMethod>();

  const [selectedShippingMethodId, setSelectedShippingMethodId] = useState<
    string
  >("");

  useEffect(() => {
    if (allDigitalItems) {
      onShippingFeeChange(0);
      setDeliveryMethod("DIGITAL");
    }
  }, [allDigitalItems]);

  const onPickupOptionSelected = () => {
    setDeliveryTypeOption("PICKUP");

    setDeliveryMethod("PICKUP_FROM_STORE");

    const { pickupFee } = pickupOption;

    if (pickupFee === null) return;

    onShippingFeeChange(pickupFee);
  };

  const onStoreShippingOptionSelected = () => {
    setDeliveryTypeOption("STORE_SHIPPING");
    setDeliveryMethod("DOOR_DELIVERY");
    onShippingFeeChange(null);
    setSelectedShippingMethodId("");
  };

  const onThirdPartyShippingOptionSelected = () => {
    setDeliveryTypeOption("THIRD_PARTY_SHIPPING");
    setDeliveryMethod("DOOR_DELIVERY");
    onShippingFeeChange(null);
    setSelectedShippingMethodId("");
  };

  const onShippingMethodSelected = (value: any) => {
    const selectedOptionId = value as string;

    setSelectedShippingMethodId(selectedOptionId);

    const selectedShippingMethod = merchantShippingOptions.find((option) => {
      return option.id === selectedOptionId;
    }) as ShippingOption;

    onShippingFeeChange(selectedShippingMethod.price);
  };

  const submitButtonHandler = () => {
    if (!deliveryMethod) return;

    if (deliveryMethod === "DOOR_DELIVERY" && !selectedShippingMethodId) return;

    onSubmitHandler(deliveryMethod, selectedShippingMethodId);
  };

  return (
    <Container>
      {(allDigitalItems ||
        pickupOption.pickupAvailable ||
        merchantShippingOptions.length > 0 ||
        thirdPartyShippingOptions.length > 0) && (
        <>
          <TitleMessageContainer>
            <p>
              <FormattedMessage id="howOrderDelivered" defaultMessage="How do you want your order delivered?" />
            </p>
          </TitleMessageContainer>

          {allDigitalItems && (
            <DeliveryOptionContainer>
              <DeliveryOption
                title="Email"
                description={translate('emailDeliveryDesc', 'Item(s) will be delivered via email')}
                active={deliveryMethod === "DIGITAL"}
                onSelect={() => {}}
              >
                <div>
                  <p>{formState.email}</p>
                </div>
              </DeliveryOption>
            </DeliveryOptionContainer>
          )}

          {!allDigitalItems && (
            <DeliveryOptionContainer>
              <DeliveryOption
                active={deliveryTypeOption == "PICKUP"}
                disabled={!pickupOption.pickupAvailable}
                title={translate('pickUp', ' Pick up from Store location')}
                description={translate('pickUpDesc', "You would be responsible for picking up your order from the store's warehouse or specified pickup station.")}
                onSelect={onPickupOptionSelected}
              >
                <StoreAddressContainer>
                  <div>
                    <p>
                      <FormattedMessage id="pickUpLocation" defaultMessage="Pickup location:" />
                    </p>
                    <p>{pickupOption.pickupLocation}</p>
                  </div>

                  <div>
                    <p>
                      <FormattedMessage id="pickUpFee" defaultMessage="Pickup fee:" />
                    </p>
                    <p>
                      {pickupOption.pickupFee === 0
                        ? "Free"
                        : getFormattedPrice(
                            pickupOption.pickupFee || 0,
                            currencyCode
                          )}
                    </p>
                  </div>
                </StoreAddressContainer>
              </DeliveryOption>
            </DeliveryOptionContainer>
          )}

          {merchantShippingOptions.length > 0 && !allDigitalItems && (
            <DeliveryOptionContainer>
              <DeliveryOption
                active={deliveryTypeOption === "STORE_SHIPPING"}
                title={translate('deliveredByStore', 'Delivered by Store')}
                description={translate('deliveredByStoreDesc', 'Please select a shipping zone below. Your shipping address MUST fall within the shipping zone you select.')}
                onSelect={onStoreShippingOptionSelected}
              >
                <SelectDropdown
                  value={selectedShippingMethodId}
                  placeholder={translate('selectShippingZone', 'Select a shipping zone...')}
                  onChange={onShippingMethodSelected}
                  options={merchantShippingOptions.map((shippingOption) => {
                    const { id, shippingAreaName } = shippingOption;

                    return { label: shippingAreaName, value: id };
                  })}
                  valueBuilder={(value) => {
                    const selectedOption = merchantShippingOptions.find(
                      (option) => {
                        return option.id === selectedShippingMethodId;
                      }
                    );

                    if (!selectedOption) return null;

                    const {
                      shippingAreaName,
                      deliveryPeriod,
                      price,
                    } = selectedOption;

                    const sliceAreaName1 = shippingAreaName.slice(0, 12);
                    const sliceAreaName2 = shippingAreaName.slice(12);

                    return (
                      <ShippingOptionValueContent
                        courierName={translate('flatFeeFor', `${sliceAreaName1}`, {area: sliceAreaName2})}
                        deliveryPeriod={deliveryPeriod}
                        currencyCode={currencyCode}
                        price={price}
                      />
                    );
                  }}
                />
              </DeliveryOption>
            </DeliveryOptionContainer>
          )}

          {thirdPartyShippingOptions.length > 0 && !allDigitalItems && (
            <DeliveryOptionContainer>
              <DeliveryOption
                active={deliveryTypeOption === "THIRD_PARTY_SHIPPING"}
                title={translate('thirdPartyShipping', "Shipped by 3'rd party courier")}
                description={translate('thirdPartyShippingDesc', 'This order will be delivered to your preferred location.')}
                onSelect={onThirdPartyShippingOptionSelected}
              >
                <SelectDropdown
                  value={selectedShippingMethodId}
                  onChange={onShippingMethodSelected}
                  options={thirdPartyShippingOptions.map((shippingOption) => {
                    const { courierName, id } = shippingOption;

                    return { label: courierName, value: id };
                  })}
                  valueBuilder={(value) => {
                    const selectedOption = thirdPartyShippingOptions.find(
                      (option) => {
                        return option.id === selectedShippingMethodId;
                      }
                    );

                    if (!selectedOption) return null;

                    const {
                      courierName,
                      deliveryPeriod,
                      price,
                    } = selectedOption;

                    return (
                      <ShippingOptionValueContent
                        courierName={courierName}
                        deliveryPeriod={deliveryPeriod}
                        currencyCode={currencyCode}
                        price={price}
                      />
                    );
                  }}
                />
              </DeliveryOption>
            </DeliveryOptionContainer>
          )}

          <SubmitControlContainer>
            <Button
              text={<FormattedMessage id="makePayment" defaultMessage="Make Payment" />}
              color="PRIMARY"
              onClick={submitButtonHandler}
              loading={submitInProgress}
              disabled={!deliveryMethod}
              containerStyle={{ width: "100%", marginBottom: "15px" }}
            />
          </SubmitControlContainer>
        </>
      )}

      {!pickupOption.pickupAvailable && merchantShippingOptions.length === 0 && (
        <p>
          <FormattedMessage id="noDeliveryOptions" defaultMessage="There are no delivery options available for the shipping information
          you provided." />
          <a href="#" onClick={navigateToPreviousStep}>
            {" "}
            <FormattedMessage id="changeAddress" defaultMessage="Change address" />
          </a>
        </p>
      )}
    </Container>
  );
}
