import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { getFormattedPrice } from '../../util/currencyUtil';
import AsyncImage from '../../components/AsyncImage';
import { CartActionType, CartItem } from '../../store/cart/types';
import VariantValue from '../VariantValue';
import { ColorTheme, PAGE_SIDE_PADDING, ScreenSize } from '../../styling/constants';
import { AppState } from '../../store/RootReducer';
import { connect } from 'react-redux';
import { Button } from '../../components/Button';
import { useHistory } from 'react-router-dom';
import { StoreDetails } from '../../entities/StoreDetails';
import debounce from 'lodash.debounce';
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { clearAddToCartNotification } from '../../store/cart/actions';
import { useViewport } from '../../providers/ViewportProvider';
import { FormattedMessage } from 'react-intl';
import translate from 'translations/utils';

const Container = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;

  border: solid 1px rgba(151, 151, 151, 0.17);
  background-color: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.19);
  box-sizing: border-box;
  z-index: 100;
      
  @media (min-width: ${ScreenSize.XS}px) {
    position: fixed;
    top: 100px;
    left: auto;
    right: 10px;
    max-width: 370px;
  }
`;

const HeaderContainer = styled.div`
  position: relative;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px ${PAGE_SIDE_PADDING}px;
  background: #eef2f7;
`;

const CloseControl = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 40px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  svg {

  }
`;

const ProductDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  padding: 0px ${PAGE_SIDE_PADDING}px;
`;

const ImageContainer = styled.div`
  height: 100px;
  width: 90px;
  margin-right: 20px;
`;

const DetailsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 20px;
`;

const AmountContainer = styled.div`
  margin-bottom: 3px;

  p {
    font-size: 1.5rem;
    color: black;
  }
`;

const TitleContainer = styled.div`
  margin-bottom: 5px;

  p {
    color: ${ColorTheme.primaryColorLight};
    font-size: 1.5rem;
    font-weight: 100;
    line-height: 24px;
  }
`;

const VariantsContainer = styled.div`
  margin-bottom: 10px;
`;

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px ${PAGE_SIDE_PADDING}px;
`;

const ControlContainer = styled.div`
  flex: 1;
  margin-right: 20px;

  &:last-child {
    margin-right: 0px;
  }
`;

interface StoreStateProps {
  storeDetails: StoreDetails;
  cartItem: CartItem | null;
}

interface StoreDispatchProps {
  clearCartNotification: () => void;
}

interface OwnProps { }

type Props = StoreStateProps & StoreDispatchProps & OwnProps;

function AddToCartNotificationToast(props: Props) {
  const { storeDetails, cartItem, clearCartNotification } = props;

  const history = useHistory();

  const viewportSize = useViewport();
  const isMobileView = viewportSize.width <= ScreenSize.XS;
  
  const containerRef = useRef<HTMLDivElement | null>(null);

  const navigateToCart = () => {
    const { urlEndpoint } = storeDetails;
    history.push({ pathname: `/${urlEndpoint}/cart` });
  }

  const getTopOffset = (isMobileView: boolean) => {
    if (isMobileView) return 0;

    const { scrollY } = window;
    const topOffset = scrollY > 90 ? -90 : -scrollY;

    return topOffset;
  }

  useEffect(() => {
    const onScrollHandler = () => {
      if (!containerRef.current) return;

      const topOffset = getTopOffset(isMobileView);

      containerRef.current.style.transform = `translateY(${topOffset}px)`;
    }

    const debouncedOnScrollHandler = debounce(onScrollHandler, 0);

    window.addEventListener("scroll", debouncedOnScrollHandler, {passive: true});

    return () => {
      window.removeEventListener("scroll", debouncedOnScrollHandler);
    }
  }, [isMobileView]);


  useEffect(() => {
    // Reset proper offset
    const topOffset = getTopOffset(isMobileView);

    if (!containerRef.current) return;
    containerRef.current.style.transform = `translateY(${topOffset}px)`;
  }, [isMobileView]);

  useEffect(() => {
    const topOffset = getTopOffset(isMobileView);

    if (!containerRef.current) return;
    containerRef.current.style.transform = `translateY(${topOffset}px)`;
  }, [cartItem]);


  if (!cartItem) return null;

  const { quantity, currencyCode, productPrice, productData, variantName, variantValue } = cartItem;
  const { name, productImageUrl } = productData;

  return (
    <Container ref={containerRef}>

      <HeaderContainer>
        <p>{quantity} {' '} <FormattedMessage id="itemAddedToCart" defaultMessage="item added to cart" /></p>

        <CloseControl onClick={clearCartNotification}>
          <CloseIcon />
        </CloseControl>
      </HeaderContainer>

      <ProductDataContainer>
        <ImageContainer>
          <AsyncImage
            src={productImageUrl}
            alt="Product Image"
            imageStyle={{ objectFit: 'contain' }}
          />
        </ImageContainer>

        <DetailsContainer>

          <AmountContainer>
            <p>{getFormattedPrice(productPrice, currencyCode)}</p>
          </AmountContainer>


          <TitleContainer>
            <p style={storeDetails.whiteLabelAcquirer && {color: storeDetails.whiteLabelAcquirer.secondaryColor }}>{name}</p>
          </TitleContainer>


          {variantName && variantValue && (
            <VariantsContainer>
              <VariantValue
                variantName={variantName}
                variantValue={variantValue} />
            </VariantsContainer>
          )}

        </DetailsContainer>

      </ProductDataContainer>

      <ControlsContainer>
        <ControlContainer>
          <Button
            text={translate('viewCart', 'View Cart')}
            color="PRIMARY"
            type="OUTLINE"
            onClick={navigateToCart}
            containerStyle={{ width: '100%' }}
          />
        </ControlContainer>
      </ControlsContainer>

    </Container>
  );
}


const mapStateToProps = (state: AppState): StoreStateProps => ({
  storeDetails: state.store.storeDetails as StoreDetails,
  cartItem: state.cart.addToCartNotificationDetails
});

const mapDispatchToProps = (dispath: (action: CartActionType) => void): StoreDispatchProps => ({
  clearCartNotification() {
    dispath(clearAddToCartNotification());
  },
});


export default connect(mapStateToProps, mapDispatchToProps)(AddToCartNotificationToast);