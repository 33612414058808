import React from 'react';
import styled from "styled-components";

import { ReactComponent as ShopIcon } from './icons/shop-icon.svg';
import { ReactComponent as DeliveryIcon } from './icons/delivery-icon.svg';
import { AppState } from 'store/RootReducer';
import { connect } from 'react-redux';
import { WhiteLabelAcquirerConfigResponse } from 'entities/StoreDetails';
import { FormattedMessage } from 'react-intl';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const DeliveryOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;

  svg {
    width: 25px;
    height: 25px;
    margin-right: 20px;
    fill: #1aa7e8;
  }

  p {

  }

  &:last-child {
    margin-bottom: 0px;
  }
`

interface Props {
  pickupAvailable: boolean;
  shippingAvailable: boolean;
  whiteLabelAcquirer?: WhiteLabelAcquirerConfigResponse
}

function DeliveryOptionsContent(props: Props) {
  const { pickupAvailable, shippingAvailable, whiteLabelAcquirer } = props;

  return (
    <Container>

      {pickupAvailable && (
        <DeliveryOption>
          <ShopIcon style={whiteLabelAcquirer ? {fill: whiteLabelAcquirer.primaryColor, width: '23px'} : { width: '23px' }} />
          <p>
            <FormattedMessage id="pickUpAvailable" defaultMessage="Pickup available" />
          </p>
        </DeliveryOption>
      )}

      {shippingAvailable && (
        <DeliveryOption>
          <DeliveryIcon style={whiteLabelAcquirer && {fill: whiteLabelAcquirer.primaryColor} } />
          <p>
            <FormattedMessage id="shippingAvailable" defaultMessage="Shipping available" />
          </p>
        </DeliveryOption>
      )}

    </Container>
  );
}


const mapStateToProps = (state: AppState) => ({
  whiteLabelAcquirer: state.store.storeDetails?.whiteLabelAcquirer,
  
});

export default connect(mapStateToProps)(DeliveryOptionsContent);