import React, { ReactNode, CSSProperties } from "react";
import styled from "styled-components";
import { ColorTheme } from "../../../styling/constants";
import IconButton from "../../../components/IconButton";
import { ReactComponent as DownArrowIcon } from '../../../assets/icons/down-arrow.svg';
import { FormattedMessage } from "react-intl";

const Container = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  & > p {
    color: ${ColorTheme.primaryColorDark};
    margin-right: 10px;

    b {
      display: none;
      margin-right: 10px;
    }
  }
`;

interface Props {
  text: string;
  onClickHandler: () => void;
}

export default function ControlContent(props: Props) {
  const { text, onClickHandler } = props;

  return (
    <Container onClick={onClickHandler}>
      <p>
        <b>
          <FormattedMessage id="sortBy" defaultMessage="Sort by" />
        </b>
        {text}
      </p>
      <IconButton
        size="XS"
        image={<DownArrowIcon />}
      />
    </Container>
  );
}