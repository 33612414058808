import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import BackButton from '../../components/BackButton';
import { Button } from '../../components/Button';
import Modal from '../../components/Modal';

import OrderSummaryContent from './OrderSummaryContent';
import CheckoutStepHeader from './CheckoutStepHeader';
import DeliveryAddressForm from './DeliveryAddressForm';
import DeliveryMethodForm from './DeliveryMethodForm';
import CheckoutTypeForm from './CheckoutTypeForm';
import LoginFormContent from './LoginFormContent';


import { CartItem, CartActionType } from '../../store/cart/types';
import { RouteComponentProps, withRouter, useHistory, useParams } from 'react-router-dom';
import { AppState } from '../../store/RootReducer';

import {
  triggerFetchDeliveryMethods,
  triggerInitiatePayment
} from '../../store/ui/CheckoutView/actions';
import { triggerSignInUser } from '../../store/auth/signin/actions';
import { SignInRequest, SignInResponse } from '../../repositories/AuthRepository';
import { SignInActionType } from '../../store/auth/signin/types';
import { AuthActionType, TriggerSetAuthStatePayload, UserProfile } from '../../store/auth/auth/types';

import { CheckoutViewActionType } from '../../store/ui/CheckoutView/types';

import { Container, BackControlContainer, MainContainer, CheckoutStepsContainer, CheckoutStepContainer, CheckoutFormContainer, OrderDetailsSection, OrderSummaryContainer, } from './style';
import { GetDeliveryMethodsRequest, DeliveryMethodsData, DeliveryMethod, InitiatePaymentRequest, InitiatePaymentResponseData } from '../../repositories/OrderRepository';
import { OrderDetails } from '../../entities/OrderDetails';
import usePrevious from '../../hooks/usePrevious';
import { StoreDetails } from '../../entities/StoreDetails';
import { WEBPAY_PAYMENT_URL, WEBPAY_PAYMENT_URL_ZIB } from '../../config/properties';
import { triggerDisplayToast } from '../../store/ui/ToastBoard/actions';
import { ToastBoardActionType, TriggerDisplayToastPayload } from '../../store/ui/ToastBoard/types';
import { getPageTitlteSuffix } from '../util';
import { triggerClearAuthState, triggerSetAuthState } from '../../store/auth/auth/actions';
import { resetCustomerAddresses } from '../../store/customerAddresses/actions';
import { CustomerAddressesActionType } from '../../store/customerAddresses/types';
import { TokenStorageManager } from '../../core/api/TokenStorageManager';
import translate from 'translations/utils';

const SUBMIT_FORM_ID = 'WEBPAY_CHECKOUT_FORM';

export interface DeliveryAddressFormState {
  id?: number;
  fullName: string;
  email: string;
  phoneNumber: string;
  address: string;
  landmark?: string;

  country: string;
  state: string;
  city: string;
}

export const initialDeliveryAddressFormState: DeliveryAddressFormState = {
  fullName: '',
  email: '',
  phoneNumber: '',
  address: '',

  country: '',
  state: '',
  city: '',
};

export interface DeliveryAddressFormSubmitParams {
  useSavedAddress: boolean,
  selectedCustomerAddressId: number | null;
  formState: DeliveryAddressFormState;
}

interface RouteParams {
  storeUrlEndpoint: string;
}

interface StoreStateProps {
  storeDetails: StoreDetails;
  cartItems: CartItem[];
  orderDetails: OrderDetails | null;

  fetchingDeliveryMethods: boolean;
  errorFetchingDeliveryMethods: boolean;
  deliveryMethodsData: DeliveryMethodsData;

  initiatingPayment: boolean;
  errorInitiatingPayment: boolean;
  initiatePaymentResponseData: InitiatePaymentResponseData;

  loginLoading: boolean;
  loginError: boolean;
  loginResponse: SignInResponse | null;

  userProfile: UserProfile | null;
}

interface StoreDispatchProps {
  displayToast: (payload: TriggerDisplayToastPayload) => void;
  signInUser: (request: SignInRequest) => void;
  setAuth: (payload: TriggerSetAuthStatePayload) => void;
  clearAuth: () => void;
  resetCustomerAddresses: () => void;
  fetchDeliveryMethods: (request: GetDeliveryMethodsRequest) => void;
  initiatePayment: (request: InitiatePaymentRequest) => void;
}

interface OwnProps {

}

type Props = RouteComponentProps<RouteParams> & StoreStateProps & StoreDispatchProps & OwnProps;


function CheckoutView(props: Props) {
  const {
    storeDetails,
    cartItems,
    orderDetails,

    fetchingDeliveryMethods,
    errorFetchingDeliveryMethods,
    deliveryMethodsData,

    initiatingPayment,
    errorInitiatingPayment,
    initiatePaymentResponseData,

    displayToast,
    fetchDeliveryMethods,
    initiatePayment,


    loginLoading,
    loginError,
    loginResponse,

    userProfile,
    signInUser,

    setAuth,
    clearAuth,
    resetCustomerAddresses
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    const { name } = storeDetails;
    document.title = `Checkout - ${getPageTitlteSuffix(name)}`;
  }, []);

  const prevFetchingDeliveryMethods = usePrevious(fetchingDeliveryMethods);
  const prevInitiatingPayment = usePrevious(initiatingPayment);
  const prevLoginLoading = usePrevious(loginLoading);

  const history = useHistory();

  const { storeUrlEndpoint } = useParams<RouteParams>();



  const [deliveryAddressFormState, setDeliveryAddressFormState] = useState<DeliveryAddressFormState>(initialDeliveryAddressFormState);

  const [useSavedAddress, setUseSavedAddress] = useState<boolean | null>(null);

  const [selectedCustomerAddressId, setSelectedCustomerAddressId] = useState<number | null>(null);



  const [shippingFee, setShippingFee] = useState<number | null>(null);

  const checkoutTypeSectionRef = useRef<HTMLDivElement | null>(null);
  const deliveryAddressSectionRef = useRef<HTMLDivElement | null>(null);
  const deliveryMethodSectionRef = useRef<HTMLDivElement | null>(null);

  const [activeStep, setActiveStep] = useState(userProfile !== null ? 1 : 0);

  const goToCheckoutTypeSection = () => {
    setActiveStep(0);
    const { current } = checkoutTypeSectionRef;

    if (!current) return;

    setTimeout(() => {
      current.scrollIntoView();
    }, 0);
  }

  const goToDeliveryAddressSection = () => {
    setActiveStep(1);
    const { current } = deliveryAddressSectionRef;

    if (!current) return;

    
    setTimeout(() => {
      current.scrollIntoView({});
    }, 0);
  }

  const goToDeliveryMethodSection = () => {
    setActiveStep(2);
    const { current } = deliveryMethodSectionRef;

    if (!current) return;

    setTimeout(() => {
      current.scrollIntoView({});
    }, 0);
  }


  const [isLoginOpen, setIsLoginOpen] = useState(false);

  const openLoginModal = () => setIsLoginOpen(true);

  const closeLoginModal = () => setIsLoginOpen(false);



  const continueAsGuestHandler = () => {
    if (isLoginOpen) closeLoginModal();
    goToDeliveryAddressSection();
  };


  const onCheckoutTypeEdit = () => {
    // Sign out customer
    clearAuth();

    // Reset use saved address and customer address id
    setUseSavedAddress(null);
    setSelectedCustomerAddressId(null);

    // Reset delivery address
    setDeliveryAddressFormState(initialDeliveryAddressFormState);

    // Clear customer addresses
    resetCustomerAddresses();

    goToCheckoutTypeSection();
  };



  const navigateToCart = () => {
    history.push({ pathname: `/${storeUrlEndpoint}/cart` });
  };

  const navigateToStore = () => {
    history.push({ pathname: `/${storeUrlEndpoint}/` });
  };



  const onDeliveryAddressFormSubmit = (props: DeliveryAddressFormSubmitParams) => {
    const { useSavedAddress, selectedCustomerAddressId, formState } = props;

    setUseSavedAddress(useSavedAddress);

    setSelectedCustomerAddressId(selectedCustomerAddressId);

    setDeliveryAddressFormState(formState);

    if (!orderDetails) return;

    fetchDeliveryMethods({
      orderId: orderDetails.orderId,
      country: formState.country,
      state: formState.state,
      city: formState.city,
    });
  };

  const onDeliveryMethodFormSubmit = (deliveryMethod: DeliveryMethod, shippingOptionId: string | null) => {
    if (!orderDetails) return;

    const { id } = storeDetails;
    const { orderId } = orderDetails;

    const { country, state, city, address, fullName, phoneNumber, email } = deliveryAddressFormState;

    initiatePayment({
      storeId: id,
      orderId: orderId,
      customerName: fullName,
      customerEmail: email,
      customerPhoneNumber: phoneNumber,
      country: country,
      orderShippingInfo: {
        deliveryMethod: deliveryMethod,
        country: country,
        state: state,
        city: city,
        address: address,
        shippingOptionId: shippingOptionId || undefined,
      }
    });
  };

  const completePayment = () => {
    const { merchantCode, payableCode, urlEndpoint, whiteLabelAcquirer } = storeDetails;

    const { amountToBePaid, paymentReference } = initiatePaymentResponseData;

    if (!orderDetails) return;

    const { orderId } = orderDetails;

    const oldFormEl = document.querySelector(`#${SUBMIT_FORM_ID}`);
    if (oldFormEl) {
      document.removeChild(oldFormEl);
    }

    const formEl = document.createElement('form');
    formEl.id = SUBMIT_FORM_ID;
    formEl.method = 'POST';
    formEl.action = whiteLabelAcquirer.acquiredBy === 'ZIB' ? WEBPAY_PAYMENT_URL_ZIB : WEBPAY_PAYMENT_URL;
    formEl.style.display = 'none';

    const inputEls = [];

    const merchantCodeInputEl = document.createElement('input');
    merchantCodeInputEl.name = 'merchant_code';
    merchantCodeInputEl.value = merchantCode;
    inputEls.push(merchantCodeInputEl);

    const payItemIdInputEl = document.createElement('input');
    payItemIdInputEl.name = 'pay_item_id';
    payItemIdInputEl.value = payableCode;
    inputEls.push(payItemIdInputEl);

    const currencyInputEl = document.createElement('input');
    currencyInputEl.name = 'currency';
    const { currencyCode } = cartItems[0];
    currencyInputEl.value = currencyCode || '566';
    inputEls.push(currencyInputEl);

    const amountInputEl = document.createElement('input');
    amountInputEl.name = 'amount';
    amountInputEl.value = `${amountToBePaid}`;
    inputEls.push(amountInputEl);

    const transactionRefInputEl = document.createElement('input');
    transactionRefInputEl.name = 'txn_ref';
    transactionRefInputEl.value = paymentReference;
    inputEls.push(transactionRefInputEl);

    const { fullName } = deliveryAddressFormState;
    const customerNameInputEl = document.createElement('input');
    customerNameInputEl.name = 'cust_name';
    customerNameInputEl.value = fullName;
    inputEls.push(customerNameInputEl);

    const { email } = deliveryAddressFormState;
    const customerIdInputEl = document.createElement('input');
    customerIdInputEl.name = 'cust_id';
    customerIdInputEl.value = email;
    inputEls.push(customerIdInputEl);

    const customerEmailInputEl = document.createElement('input');
    customerEmailInputEl.name = 'cust_email';
    customerEmailInputEl.value = email;
    inputEls.push(customerEmailInputEl);

    const { phoneNumber } = deliveryAddressFormState;
    const customerMobileNoInputEl = document.createElement('input');
    customerMobileNoInputEl.name = 'cust_mobile_no';
    customerMobileNoInputEl.value = phoneNumber;
    inputEls.push(customerMobileNoInputEl);

    const acquiredByInputEl = document.createElement('input');
    acquiredByInputEl.name = 'acquired_by';
    acquiredByInputEl.value = whiteLabelAcquirer.acquiredBy;
    inputEls.push(acquiredByInputEl);

    const appBaseUrl = window.location.origin;

    const siteRedirectUrlInputEl = document.createElement('input');
    siteRedirectUrlInputEl.name = 'site_redirect_url';
    siteRedirectUrlInputEl.value = `${appBaseUrl}/${urlEndpoint}/order-status/${orderId}`;
    inputEls.push(siteRedirectUrlInputEl);

    const displayModeInputEl = document.createElement('input');
    displayModeInputEl.name = 'display_mode';
    displayModeInputEl.value = 'PAGE';
    inputEls.push(displayModeInputEl);

    if (userProfile && TokenStorageManager.getToken()) {
      const passportSessionIdInputEl = document.createElement('input');
      passportSessionIdInputEl.name = 'passportsessionid';
      passportSessionIdInputEl.value = TokenStorageManager.getToken() as string;
      inputEls.push(passportSessionIdInputEl);
    }

    inputEls.forEach(inputEl => {
      formEl.appendChild(inputEl);
    });

    document.body.appendChild(formEl);

    formEl.submit();
  };

  useEffect(() => {
    if (!orderDetails) {
      history.push({ pathname: `/${storeUrlEndpoint}/cart` });
      return;
    }
  }, []);

  useEffect(() => {
    if (!(!loginLoading && prevLoginLoading)) return;

    if (loginError) {
      displayToast({ toastType: "ERROR" });
      return;
    }

    if (!loginResponse) return;

    const { data: { access_token, email, mobileNo } } = loginResponse;

    setAuth({
      accessToken: access_token,
      userProfile: { email, mobileNo }
    });

    closeLoginModal();

    goToDeliveryAddressSection();
  }, [loginLoading]);

  useEffect(() => {
    if (!(!fetchingDeliveryMethods && prevFetchingDeliveryMethods)) return;

    if (errorFetchingDeliveryMethods) {
      displayToast({ toastType: "ERROR" });
      return;
    }

    goToDeliveryMethodSection();
  }, [fetchingDeliveryMethods]);


  useEffect(() => {
    if (!(prevInitiatingPayment && !initiatingPayment)) return;

    if (errorInitiatingPayment) {
      displayToast({ toastType: "ERROR" });
      return;
    }

    completePayment();
  }, [initiatingPayment]);
  
  if (!orderDetails) return null;

  const allDigitalItems = orderDetails.orderItems.every(item => item.productType === "DIGITAL");

  return (
    <Container>
      <Modal
        open={isLoginOpen}
        modalTitle="Sign In with Quickteller"
        closeButtonHandler={closeLoginModal}
      >
        <LoginFormContent
          loading={loginLoading}
          continueAsGuest={continueAsGuestHandler}
          loginUser={signInUser}
        />
      </Modal>

      <BackControlContainer>
        <BackButton text={translate('backToCart', 'Back to cart')} onClick={navigateToCart} />
      </BackControlContainer>

      <MainContainer>

        <CheckoutStepsContainer>

          <CheckoutStepContainer ref={checkoutTypeSectionRef}>
            <CheckoutTypeForm
              completed={activeStep > 0}
              isGuestCheckout={userProfile === null}
              customerEmail={userProfile?.email}
              loginHandler={openLoginModal}
              continueAsGuestHandler={continueAsGuestHandler}
              editControlHandler={onCheckoutTypeEdit}
            />
          </CheckoutStepContainer>


          <CheckoutStepContainer ref={deliveryAddressSectionRef}>
            <CheckoutStepHeader
              stepNo={1}
              title={translate('shippingDetails', 'Shipping Details')}
              complete={activeStep > 1}
              editControlHandler={goToDeliveryAddressSection}
            />


            {activeStep >= 1 && (
              <CheckoutFormContainer>
                <DeliveryAddressForm
                  completed={activeStep > 1}
                  initialUseSavedAddress={useSavedAddress}
                  initialSelectedCustomerAddressId={selectedCustomerAddressId}
                  formState={deliveryAddressFormState}
                  submitInProgress={fetchingDeliveryMethods}
                  submitHandler={onDeliveryAddressFormSubmit}
                />
              </CheckoutFormContainer>
            )}


          </CheckoutStepContainer>


          <CheckoutStepContainer ref={deliveryMethodSectionRef}>
            <CheckoutStepHeader
              stepNo={2}
              title={translate('deliveryMethod', 'Delivery Method')}
              complete={activeStep > 3}
            />


            {activeStep >= 2 && (
              <CheckoutFormContainer>
                <DeliveryMethodForm
                  deliveryMethodsData={deliveryMethodsData}
                  onShippingFeeChange={(amount) => setShippingFee(amount)}
                  submitInProgress={initiatingPayment}
                  onSubmitHandler={onDeliveryMethodFormSubmit}
                  navigateToPreviousStep={goToDeliveryAddressSection}
                  allDigitalItems={allDigitalItems}
                  formState={deliveryAddressFormState}
                />
              </CheckoutFormContainer>
            )}


          </CheckoutStepContainer>

        </CheckoutStepsContainer>


        <OrderDetailsSection>

          <OrderSummaryContainer>
            <OrderSummaryContent
              cartItems={cartItems}
              shippingFee={shippingFee}
            />
          </OrderSummaryContainer>

          <Button
            text={translate('continueShopping', 'Continue shopping')}
            color="PRIMARY"
            type="OUTLINE"
            onClick={navigateToStore}
            containerStyle={{ width: '100%' }}
          />
        </OrderDetailsSection>

      </MainContainer>

    </Container>
  );
}


const mapStateToProps = (state: AppState): StoreStateProps => ({
  storeDetails: state.store.storeDetails as StoreDetails,
  cartItems: state.cart.cartItems,
  orderDetails: state.order.orderDetails,

  loginLoading: state.auth.signin.loading,
  loginError: state.auth.signin.loadingError,
  loginResponse: state.auth.signin.response,

  userProfile: state.auth.auth.userProfile,

  fetchingDeliveryMethods: state.ui.checkoutView.fetchingDeliveryMethods,
  errorFetchingDeliveryMethods: state.ui.checkoutView.errorFetchingDeliveryMethods,
  deliveryMethodsData: state.ui.checkoutView.deliveryMethodsData as DeliveryMethodsData,

  initiatingPayment: state.ui.checkoutView.initiatingPayment,
  errorInitiatingPayment: state.ui.checkoutView.errorInitiatingPayment,
  initiatePaymentResponseData: state.ui.checkoutView.initiatePaymentResponseData as InitiatePaymentResponseData,
});

const mapDispatchToProps = (dispatch: (
    action:
      ToastBoardActionType
      | CartActionType
      | CheckoutViewActionType
      | SignInActionType
      | AuthActionType
      | CustomerAddressesActionType
  ) => void): StoreDispatchProps => ({
  displayToast(payload: TriggerDisplayToastPayload) {
    dispatch(triggerDisplayToast(payload));
  },
  signInUser(request: SignInRequest) {
    dispatch(triggerSignInUser(request))
  },
  setAuth(payload: TriggerSetAuthStatePayload) {
    dispatch(triggerSetAuthState(payload));
  },
  clearAuth() {
    dispatch(triggerClearAuthState());
  },
  resetCustomerAddresses() {
    dispatch(resetCustomerAddresses());
  },
  fetchDeliveryMethods(request: GetDeliveryMethodsRequest) {
    dispatch(triggerFetchDeliveryMethods(request));
  },
  initiatePayment(request: InitiatePaymentRequest) {
    dispatch(triggerInitiatePayment(request));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CheckoutView));
