import * as React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import DropdownWrapper from "../DropdownWrapper";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import useStoreSearch from "./useStoreSearch";
import LoadingIndicator from "components/LoadingIndicator";
import { ColorTheme } from "styling/constants";
import translate from "translations/utils";

export default function StoreSearchInput() {
  const {
    searchString,
    handleSearchChange,
    showDropdown,
    closeDropdown,
    reqState,
    fetchSelectedStore,
  } = useStoreSearch();

  const onStoreClicked = (url: string) => () => fetchSelectedStore(url);

  return (
    <SearchContainer>
      <DropdownWrapper
        containerClassName="wrapper-container"
        dropdownContainerClassName="dropdown-container"
        showDropdown={showDropdown}
        outerClickHandler={closeDropdown}
        controlContent={
          <InputContainer className={showDropdown ? "active" : ""}>
            <input
              autoFocus
              aria-label="storefront search"
              type="text"
              placeholder={translate('searchStoreName', 'Search store name')}
              value={searchString}
              onChange={handleSearchChange}
            />
            <SearchIcon />
          </InputContainer>
        }
        dropdownContent={
          <SearchResultContainer>
            <p>
              <i>Auto suggestions</i>
              {reqState.loading && (
                <LoadingIndicator
                  size={15}
                  color={ColorTheme.primaryColorDark}
                />
              )}
            </p>

            <div className="results">
              {(reqState.response || []).map((store) => (
                <Link
                  key={store.urlEndpoint}
                  role="button"
                  to={`/${store.urlEndpoint}`}
                  onClick={onStoreClicked(store.urlEndpoint)}
                >
                  {store.name}
                </Link>
              ))}
            </div>
          </SearchResultContainer>
        }
      />
    </SearchContainer>
  );
}

const SearchContainer = styled.div`
  &,
  * {
    box-sizing: border-box;
  }

  width: 100%;

  .wrapper-container {
    width: 700px;
    max-width: 100%;
    margin: 0 auto;
  }

  .dropdown-container {
    width: 100%;
    border-radius: 4px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border: 1px solid #a7a9c0;
    border-top: none;
    box-shadow: 0 7px 9px -3px rgba(20, 108, 146, 0.1);
  }
`;

const InputContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  height: 40px;
  border: solid 1px rgba(151, 151, 151, 0.5);
  background-color: #fff;
  padding: 0px 20px;
  border-radius: 4px;

  display: flex;
  align-items: center;

  &.active {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-color: #a7a9c0;
  }

  & > input {
    border: none;
    height: 100%;
    flex-grow: 1;
    margin-right: 2px;
    outline: none;
    background-color: transparent;
    font-family: inherit;
  }
`;

const SearchResultContainer = styled.div`
  width: 100%;
  background-color: white;

  padding: 10px 20px;

  > p {
    display: flex;
    align-items: center;
    > i {
      font-size: 1.2rem;
      margin-right: 10px;
    }
  }

  .results {
    min-height: 80px;
    max-height: 180px;
    overflow: auto;
  }

  .results > a {
    text-decoration: none;
    color: #313131;
    cursor: pointer;
    border-radius: 4px;
    padding-left: 5px;
    margin-top: 5px;
    height: 30px;
    display: flex;
    align-items: center;
    &:hover {
      background-color: rgba(238, 242, 247, 1);
    }
  }
`;
