import React from 'react';
import NotificationWidget from "../../components/NotificationWidget";
import { ReactComponent as CartIcon } from '../../assets/icons/shopping-cart.svg';
import translate from 'translations/utils';


export default function EmptyCartView() {
  return (
    <NotificationWidget
      title={translate('noItemsInCart', 'No items in your cart')}
      message={translate('addSomethingToCart', 'Go ahead and add something!')}
      icon={<CartIcon />}
    />
  );
}