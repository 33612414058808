import * as React from 'react';
import { createIntl, createIntlCache } from 'react-intl';
import { messages } from './messages';
// eslint-disable-next-line import/no-mutable-exports, prefer-const
export const lang = 'en';
const cache = createIntlCache();
let intl = createIntl(
  {
    locale: 'en',
    // eslint-disable-next-line dot-notation
    messages: messages['en']
  },
  cache
);
export function changeLanguage(lang: string) {
  const newIntl = createIntl(
    {
      locale: lang,
      messages: messages[lang]
    },
    cache
  );
  intl = newIntl;
}
const translate = (id: string, defaultMessage: string, values?: {}) =>
  intl.formatMessage({ id, defaultMessage }, values);
export default translate;