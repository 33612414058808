import styled from 'styled-components';

import { PAGE_SIDE_PADDING, HEADER_HEIGHT, HEADER_HEIGHT_XS, MAX_STORE_VIEW_WIDTH, ScreenSize, ColorTheme } from '../../styling/constants';

export const Container = styled.header`
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  background-color: #eef2f7;

  @media (min-width: ${ScreenSize.XS}px) {
    height: ${HEADER_HEIGHT_XS}px;   
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  max-width: ${MAX_STORE_VIEW_WIDTH}px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px ${PAGE_SIDE_PADDING}px;
  box-sizing: border-box;
  flex-shrink: 0;
  margin: auto;
`;

export const InitialsContainer = styled.div`
  width: 45px;
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #003c58;
  border-radius: 4px;
  margin-right: 26px;

  p {
    color: white;
    font-size: 2rem;
  }
`;


export const LogoContainer = styled.div`
  margin-right: 20px;
  border-radius: 3px;
  overflow: hidden;

  img {
    width: 35px;
    height: 35px;
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TitleContainer = styled.div`
  margin-bottom: 8px;
  cursor: pointer;
  
  h2 {
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: bold;
    color: ${ColorTheme.primaryColorDark};
  }

  @media (min-width: ${ScreenSize.XS}px) {
    margin-bottom: 10px;

    h2 {
      font-size: 1.8rem;
    }
  }
`;

export const ProviderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    margin: 0px 5px 0px 0px;
    font-size: 1.2rem;
    line-height: 1.2rem;
    color: rgba(0, 70, 99, 0.6);
    flex-shrink: 0;
  }

  img {
    display: none;
    height: 16px;
    margin-bottom: 2px;
  }

  @media (min-width: ${ScreenSize.XS}px) {
    p {
      font-size: 1.2rem;
    }
  }
`;

export const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  height: 100%;
`;

export const ActionControl = styled.div`
  
`;

export const TranslationButtons = styled.div`
  margin-left: 60px;
  
  > button {
    background-color: transparent;
    color: #003c58;
    font-weight: 700;
    border: none;
    cursor: pointer;
    padding: 10px;
    /* margin-top: 20px; */
    font-size: 12px;
  }
  > button:hover {
    color: #ee312a;
  }
`;
export const TranslationSwitch = styled.div`
  margin-left: 10px;
`;