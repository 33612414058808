import React, { ReactNode } from "react";
import styled from "styled-components";
import IconButton from "../../../components/IconButton";
import CloseIcon from "../../../assets/icons/close.svg";
import { PAGE_SIDE_PADDING, ScreenSize } from "../../../styling/constants";
import { Button } from "../../../components/Button";
import CartItemEntry from "./CartItemEntry";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { AppState } from "store/RootReducer";



const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: white;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.15);

  display: flex;
  flex-direction: column;

  @media (min-width: ${ScreenSize.XS}px) {
    height: 500px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  padding: 0px ${PAGE_SIDE_PADDING}px;
`;

const HeaderTitleContainer = styled.div`
  h6 {
    font-size: 1.4rem;
    font-weight: 100;
  }
`;

const CheckoutItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  padding: 30px ${PAGE_SIDE_PADDING}px;
  padding-bottom: 0px;
`;

const CartItemContainer = styled.div`
  margin-bottom: 30px;
`;

const CheckoutContainer = styled.div`
  padding: ${PAGE_SIDE_PADDING}px;
  border-top: 1px solid rgba(151, 151, 151, 0.17);
`;

const TotalTitleAmountContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const TotalTitleContainer = styled.div`

`;

const TotalAmountContainer = styled.div`

`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const CartButtonContainer = styled.div`
  width: 100px;
  margin-right: 20px;
`;

const CheckoutButtonContainer = styled.div`
  flex: 1;
`;


export default function DropdownContent() {
  const { whiteLabelAcquirer } = useSelector((state: AppState) => ({
      whiteLabelAcquirer: state.store.storeDetails?.whiteLabelAcquirer
  }));

  
  return (
    <Container>

      <Header>
        <HeaderTitleContainer>
          <h6><FormattedMessage id="itemsInCart" defaultMessage="Items in cart" /> <b>(6)</b></h6>
        </HeaderTitleContainer>

        <IconButton
          size="S"
          image={<img src={CloseIcon} alt="Close Icon" />}
          onClickHandler={() => { }}
        />
      </Header>

      <CheckoutItemsContainer>
        {/* {sampleItems.map(checkoutItem => {
          return (
            <CartItemContainer>
              <CartItemEntry />
            </CartItemContainer>
          );
        })} */}
      </CheckoutItemsContainer>

      <CheckoutContainer>

        <TotalTitleAmountContainer>
          <TotalTitleContainer>
            <p>
              <FormattedMessage id="subtotal" defaultMessage="Subtotal" />
            </p>
          </TotalTitleContainer>

          <TotalAmountContainer>
            <p>{whiteLabelAcquirer?.acquiredBy === 'MUL' ? 'CDF' : 'NGN'} 17,500.00</p>
          </TotalAmountContainer>

        </TotalTitleAmountContainer>

        <ButtonsContainer>

          <CartButtonContainer>
            <Button
              type="OUTLINE"
              text={<FormattedMessage id="cart" defaultMessage="CART" />}
              color="SECONDARY"
              onClick={() => { }}
              containerStyle={{ width: '100%' }}
            />
          </CartButtonContainer>

          <CheckoutButtonContainer>
            <Button
              text={<FormattedMessage id="checkout" defaultMessage="CHECKOUT" />}
              color="PRIMARY"
              onClick={() => { }}
              containerStyle={{ width: '100%' }}
            />
          </CheckoutButtonContainer>

        </ButtonsContainer>


      </CheckoutContainer>
    </Container>
  );
}