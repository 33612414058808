import React from 'react';
import { ConnectedRouter as Router } from 'connected-react-router';
import { store, history } from "./store";
import { Provider } from "react-redux";
import { GlobalStyle } from './styling/global';
import RootView from './views/RootView';
import {IntlProvider} from 'react-intl';
import { TranslationContext } from './contexts/TranslationContext';
import { messages } from 'translations/messages';
import './styling/fonts.css';
function App() {
  const [lang, setLang] = React.useState('en');
  const memoizedLang = React.useMemo(() => ({lang, setLang}), [lang]);
  return (
    <>
      <GlobalStyle />
      <Provider store={store}>
        <TranslationContext.Provider value={memoizedLang}>
          <IntlProvider locale={lang} messages={messages[lang]}>
            <Router history={history}>
              <RootView />
            </Router>
          </IntlProvider>
        </TranslationContext.Provider>
      </Provider>
    </>
  );
}
export default (App);