import React from 'react';
import BackButton from '../../components/BackButton';
import ProductImagesList from './ProductImagesList';
import { SkeletonBox, SkeletonText } from '../../components/Skeleton';

import {
  Container,
  BackControlContainer,
  CheckoutSection,
  ProductImagesContainer,
  ProductActiveImage,
  ProductImageListItemContainer,
  ProductDataContainer,
  ProductTitleContainer,
  ProductPriceContainer,
  StatusBadgesContainer,
  CategorySection,
  CategoryTitleContainer,
  ProductInfoSectionsContainer,
} from './style';
import translate from 'translations/utils';

interface Props {
  backButtonHandler: () => void;
}

export default function SkeletonView(props: Props) {

  const { backButtonHandler } = props;

  return (
    <Container>
      <BackControlContainer>
        <BackButton text={translate('backToProducts', 'Back to products')} onClick={backButtonHandler} />
      </BackControlContainer>

      <CheckoutSection>

        <ProductImagesContainer>

          <ProductActiveImage>
            <SkeletonBox style={{ width: '100%' }} />
          </ProductActiveImage>

          <ProductImagesList>
            {Array.from({ length: 3 }).map(_ => {

              return (
                <ProductImageListItemContainer>
                  <SkeletonBox />
                </ProductImageListItemContainer>
              );
            })}
          </ProductImagesList>

        </ProductImagesContainer>

        <ProductDataContainer>
          <ProductTitleContainer>
            <h4>
              <SkeletonText style={{ width: '100%', marginBottom: '7px' }} />
              <SkeletonText style={{ width: '200px' }} />
            </h4>
          </ProductTitleContainer>

          <ProductPriceContainer>
            <h4>
              <SkeletonText style={{ width: '100px' }} />
            </h4>
          </ProductPriceContainer>

          <StatusBadgesContainer>
            <SkeletonBox />
          </StatusBadgesContainer>

          {Array.from({ length: 2 }).map(_ => {
            return (
              <CategorySection>
                <CategoryTitleContainer>
                  <h6><SkeletonText style={{ width: '70px' }} /></h6>
                </CategoryTitleContainer>

                <SkeletonBox style={{ width: '170px', height: '30px' }} />

              </CategorySection>
            );
          })}

          <SkeletonBox style={{ width: '100px', height: '30px' }} />

        </ProductDataContainer>

      </CheckoutSection>


      <ProductInfoSectionsContainer>

        <SkeletonBox style={{ height: '30px', width: '160px', marginBottom: '30px' }} />

        <p><SkeletonText style={{ marginBottom: '10px', height: '15px' }} /></p>
        <p><SkeletonText style={{ marginBottom: '10px' }} /></p>
        <p><SkeletonText style={{ marginBottom: '10px' }} /></p>
        <p><SkeletonText style={{ marginBottom: '10px' }} /></p>
        <p><SkeletonText style={{ marginBottom: '30px', width: '65%' }} /></p>

        <p><SkeletonText style={{ marginBottom: '10px' }} /></p>
        <p><SkeletonText style={{ marginBottom: '10px' }} /></p>
        <p><SkeletonText style={{ width: '25%' }} /></p>

      </ProductInfoSectionsContainer>

    </Container>
  );
}