import * as React from "react";

import { FilterContainer, FilterHeaderButton, Categories } from "./styles";
import { ReactComponent as DownArrowIcon } from "../../../assets/icons/down-arrow.svg";
import ProductGroup from "entities/ProductGroup";
import Checkbox from "components/Checkbox";
import { FormattedMessage } from "react-intl";

interface Props {
  categories: ProductGroup[];
  value: number[];
  onChange: (v: number[]) => void;
}
export default function Categoriesfilter(props: Props) {
  const { categories, onChange, value } = props;
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const toggleCollapse = () => setIsCollapsed((v) => !v);

  const onCategorySelcted = (category: ProductGroup) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.checked) {
      onChange([...value, category.id]);
    } else {
      onChange(value.filter((c) => c !== category.id));
    }
  };

  return (
    <FilterContainer>
      <FilterHeaderButton
        onClick={toggleCollapse}
        className={isCollapsed ? "close" : ""}
      >
        <span>
          <FormattedMessage id="categories" defaultMessage="Categories" />
        </span>
        <DownArrowIcon />
      </FilterHeaderButton>
      <Categories className={isCollapsed ? "close" : ""}>
        {categories.map(
          (category) =>
            category.name.toLowerCase() !== "default" && (
              <div key={category.id} className="category-item">
                <Checkbox
                  containerClassname="checkbox"
                  label={category.name}
                  checked={value.indexOf(category.id) !== -1}
                  value={category.id}
                  onChange={onCategorySelcted(category)}
                />
              </div>
            )
        )}
      </Categories>
    </FilterContainer>
  );
}
