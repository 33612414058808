import React, { useState } from 'react';
import styled from 'styled-components';
import NotificationWidget from "../components/NotificationWidget";
import { ColorTheme, PAGE_SIDE_PADDING, ScreenSize } from '../styling/constants';

import LogoImage from '../assets/images/app-logo.png';
import StoreSearchInput from "components/StoreSearchInput";
import { TranslationContext } from 'contexts/TranslationContext';
import translate, { changeLanguage } from 'translations/utils';
import { FormattedMessage } from 'react-intl';
import SwitchControl from 'components/Switch';
import { EnvironmentControl } from 'components/Switch/style';
import { useSelector } from 'react-redux';
import { AppState } from 'store/RootReducer';


const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${ColorTheme.backgroundColor};
  padding: 0px ${PAGE_SIDE_PADDING}px;
  box-sizing: border-box;
`;

const ImageContainer = styled.div`
  margin: 40px 0px;

  img {
    height: 35px;
  }
`;

const NotificationContainer = styled.div`
  margin-bottom: 35px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${ScreenSize.XS}px) {
    flex-direction: row;
  }
`;

const LinkContainer = styled.div`
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 0px;
  }

  @media (min-width: ${ScreenSize.XS}px) {
    margin-bottom: 0px;
    margin-right: 50px;

    &:last-child {
      margin-right: 0px;
    }
  }
`;

const ImageAndButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    margin-left: 400px; 
  }
`;
const TranslationButtons = styled.div`
  margin-left: 400px;
  
  > button {
    background-color: transparent;
    color: #003c58;
    font-weight: 700;
    border: none;
    cursor: pointer;
    padding: 10px;
    /* margin-top: 20px; */
    font-size: 13px;
  }
  > button:hover {
    color: #ee312a;
  }
`;

interface Props {
  noStoreFound?: boolean;
  storeClosed?: boolean;
  storeName?: string;
}

export default function InvalidStoreView(props: Props) {
  const {
    noStoreFound = false,
    storeClosed = false,
    storeName = "Store",
  } = props;

  const { setLang } = React.useContext(TranslationContext);

  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    if(checked) {
      setChecked(false);
      setLang('en');
      changeLanguage('en');
    } else {
      setChecked(true);
      setLang('fr');
      changeLanguage('fr');
    }
  };

  const { whiteLabelAcquirer } = useSelector((state: AppState) => ({
      whiteLabelAcquirer: state.store.storeDetails?.whiteLabelAcquirer
  }));

  return (
    <Container>
      <ImageAndButtonContainer>
        <ImageContainer>
          <img src={LogoImage} alt="Logo" />
        </ImageContainer>
        
        {
          whiteLabelAcquirer?.acquiredBy === 'MUL' && (
            <div>
              <EnvironmentControl>
                <span className='en-span'>EN</span>
              <SwitchControl active={checked} activeColor='#2EB57E' onClickHandler={handleChange} />
              <span className='fr-span'>FR</span>
              </EnvironmentControl>
            </div>
          )
        }
      </ImageAndButtonContainer>

      <NotificationContainer>
        {noStoreFound && (
          <>
            <NotificationWidget
              title={translate('storefrontNotFound', 'Oops! Storefront Not Found')}
              message={translate('storefrontNotFoundDesc', 'The storefront you are trying to view was not found or does not exist. Kindly confirm the url is correct or use the search box below to search for the appropriate storefront.')}
              type="ERROR"
            />
            <StoreSearchInput />
          </>
        )}

        {storeClosed && (
          <NotificationWidget
            title={`${storeName} ${translate('storeIsClodes', 'is Closed')}`}
            message={translate('storeIsClosedDesc', 'The store is currently closed. Please contact support for further assistance.')}
            type="ERROR"
          />
        )}
      </NotificationContainer>

      <LinksContainer>
        <LinkContainer>
        <FormattedMessage id='aboutQTBStorefront' defaultMessage='About Quickteller Storefront' />
        </LinkContainer>

        <LinkContainer>
        <FormattedMessage id='setupStoreForFree' defaultMessage='Setup a store for FREE' />
        </LinkContainer>
      </LinksContainer>
    </Container>
  );
}
