import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { Button } from '../../../components/Button';
import { AppState } from '../../../store/RootReducer';
import { CheckoutViewActionType } from '../../../store/ui/CheckoutView/types';
import { DeliveryAddressFormState, DeliveryAddressFormSubmitParams, initialDeliveryAddressFormState } from '..';
import { SubmitControlContainer } from '../style';
import { UserProfile } from '../../../store/auth/auth/types';
import NewAddressForm from './NewAddressForm';
import { triggerFetchCustomerAddresses } from '../../../store/customerAddresses/actions';
import { CustomerAddress, GetCustomerAddressesRequest } from '../../../repositories/CustomerRepository';
import { CustomerAddressesActionType } from '../../../store/customerAddresses/types';
import RadialButton from '../../../components/RadialButton';
import CustomerAddressBook from './CustomerAddressBook';
import SelectedAddressContent from './SelectedAddressContent';
import usePrevious from '../../../hooks/usePrevious';
import { FormattedMessage } from 'react-intl';
import translate from 'translations/utils';


const SavedAddressContainer = styled.div`
  margin-bottom: 30px;
`;

const AddressTypeOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 35px;
`;

const AddressTypeOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 40px;

  p {
    margin-left: 10px;
  }
`;

interface StoreStateProps {
  userProfile: UserProfile | null;

  customerAddresses: CustomerAddress[] | null;
  customerAddressesLoading: boolean;
  customerAddressesLoadingError: boolean;
}

interface StoreDispatchProps {
  triggerfetchCustomerAddresses: (request: GetCustomerAddressesRequest) => void;
}

interface OwnProps {
  initialUseSavedAddress: boolean | null;
  initialSelectedCustomerAddressId: number | null;
  formState: DeliveryAddressFormState;
  submitInProgress: boolean;

  submitHandler: (props: DeliveryAddressFormSubmitParams) => void;
}

type Props = StoreStateProps & StoreDispatchProps & OwnProps;


function EditContent(props: Props) {
  const {
    userProfile,

    customerAddresses,
    customerAddressesLoading,
    customerAddressesLoadingError,

    triggerfetchCustomerAddresses,

    initialUseSavedAddress,
    initialSelectedCustomerAddressId,
    formState,
    submitInProgress,

    submitHandler,
  } = props;

  const isGuestCheckout = userProfile === null;

  const [useSavedAddress, setUseSavedAddress] = useState(
    initialUseSavedAddress != null ? initialUseSavedAddress : !isGuestCheckout
  );

  const [selectedCustomerAddressId, setSelectedCustomerAddressId] = useState<number | null>(
    initialSelectedCustomerAddressId
  );

  const [newAddressFormState, setNewAddressFormState] = useState<DeliveryAddressFormState>(
    initialUseSavedAddress ? initialDeliveryAddressFormState : formState
  );

  const [isAddressBookOpen, setIsAddressBookOpen] = useState(false);
  const openAddressBook = () => setIsAddressBookOpen(true);
  const closeAddressBook = () => setIsAddressBookOpen(false);



  const onSelectedAddressChange = (customerAddressId: number) => {
    setSelectedCustomerAddressId(customerAddressId);
    closeAddressBook();
  };


  const [showFormErrors, setShowFormErrors] = useState(false);

  const loadCustomerAddresses = () => {
    triggerfetchCustomerAddresses({
      email: userProfile?.email as string
    });
  };

  const getSelectedCustomerAddress = (
    customerAddresses: CustomerAddress[] | null,
    selectedCustomerAddressId: number | null
  ) => {
    if (!customerAddresses) return null;

    // check if there are any addresses available
    if (customerAddresses.length === 0) return null;

    // Find the selected address from the address book
    let selectedAddress = null

    customerAddresses.forEach(address => {
      // If user has not selected an address yet but default is available use it
      if (!selectedCustomerAddressId && address.isDefault) return address;

      // check if an address matches an id selcted by the user
      if (address.id === selectedCustomerAddressId) {
        selectedAddress = address;
      }
    });

    // if no address was selected return the first available
    if (!selectedAddress) return customerAddresses[0];

    return selectedAddress;
  }

  const onFormSubmit = () => {
    // show form errors if using new address form
    if (!useSavedAddress) setShowFormErrors(true);


    let country, state, city, address, fullName, phoneNumber, email;

    if (useSavedAddress) {
      if (!customerAddresses) return;

      const customerAddress = getSelectedCustomerAddress(
        customerAddresses,
        selectedCustomerAddressId
      );

      // return if using saved address but no adress is selcted
      if (!customerAddress) return;

      country = customerAddress.country;
      state = customerAddress.state;
      city = customerAddress.area;
      address = customerAddress.address;
      fullName = customerAddress.customerName;
      phoneNumber = customerAddress.customerPhoneNumber;
      email = customerAddress.customerEmail;
    } else {
      country = newAddressFormState.country;
      state = newAddressFormState.state;
      city = newAddressFormState.city;
      address = newAddressFormState.address;
      fullName = newAddressFormState.fullName;
      phoneNumber = newAddressFormState.phoneNumber;
      email = isGuestCheckout ? newAddressFormState.email : userProfile?.email;
    }

    // Validate that all parameters are set
    if (!country || !state || !city || !address || !fullName || !phoneNumber || !email) {
      return;
    }

    const formState = { country, state, city, address, fullName, phoneNumber, email };

    submitHandler({
      useSavedAddress,
      selectedCustomerAddressId: useSavedAddress ? selectedCustomerAddressId : null,
      formState
    });
  };

  // Fetch customer addresses if customer is signed in
  useEffect(() => {
    if (isGuestCheckout) return;

    // Do not reload customer addresses if they were loaded previously
    if (customerAddresses) return;

    loadCustomerAddresses();
  }, []);


  const selectedCustomerAddress = getSelectedCustomerAddress(
    customerAddresses,
    selectedCustomerAddressId
  );


  return (
    <>
      <CustomerAddressBook
        open={isAddressBookOpen}
        selectedAddressId={selectedCustomerAddress?.id || null}
        closeButtonHandler={() => setIsAddressBookOpen(false)}
        onSelectedAddressChange={onSelectedAddressChange}
      />

      {!isGuestCheckout && (
        <AddressTypeOptionsContainer>
          <AddressTypeOption>
            <RadialButton
              active={useSavedAddress}
              onClick={() => setUseSavedAddress(true)}
            />
            <p>
              <FormattedMessage id="addressBook" defaultMessage="Adress Book" />
            </p>
          </AddressTypeOption>

          <AddressTypeOption>
            <RadialButton
              active={!useSavedAddress}
              onClick={() => setUseSavedAddress(false)}
            />
            <p>
              <FormattedMessage id="newAddress" defaultMessage="New Address" />
            </p>
          </AddressTypeOption>
        </AddressTypeOptionsContainer>
      )}

      {useSavedAddress && (
        <SavedAddressContainer>
          <SelectedAddressContent
            loading={customerAddressesLoading}
            loadingError={customerAddressesLoadingError}
            customerAddress={selectedCustomerAddress}
            reloadAddressesHandler={loadCustomerAddresses}
            openAddressBookHandler={openAddressBook}
          />
        </SavedAddressContainer>
      )}

      {(!useSavedAddress) && (
        <NewAddressForm
          initialState={newAddressFormState}
          showFormErrors={showFormErrors}
          emailEnabled={isGuestCheckout}
          onFormChange={(formState) => setNewAddressFormState(formState)}
        />
      )}

      <SubmitControlContainer>
        <Button
          text={translate('continueToDeliveryMethod', 'Continue to delivery method')}
          color="PRIMARY"
          loading={submitInProgress}
          onClick={onFormSubmit}
        />
      </SubmitControlContainer>
    </>
  );
}


const mapStateToProps = (state: AppState): StoreStateProps => ({
  userProfile: state.auth.auth.userProfile,

  customerAddresses: state.customerAddresses.customerAddresses,
  customerAddressesLoading: state.customerAddresses.customerAddressesLoading,
  customerAddressesLoadingError: state.customerAddresses.customerAddressesLoadingError,
});

const mapDispatchToProps = (
  dispath: (action: CheckoutViewActionType | CustomerAddressesActionType) => void
): StoreDispatchProps => ({
  triggerfetchCustomerAddresses(request: GetCustomerAddressesRequest) {
    dispath(triggerFetchCustomerAddresses(request));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditContent);
