import React, { useState, useEffect } from 'react';
import styled, { css, ThemeProvider } from "styled-components";
import { format as formatDate } from 'date-fns';

import { ReactComponent as CheckMarkIcon } from '../../assets/icons/check-mark.svg';
import { ColorTheme, ScreenSize } from '../../styling/constants';
import { DeliveryMethod, OrderStatus, OrderStatusDetails, ShippingOrderStatus } from '../../repositories/OrderRepository';
import { FormattedMessage } from 'react-intl';

const STEP_WIDTH = 150;



const StatusBadge = styled.div`
  width: 45px;
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: #f1f1f1;
  margin-right: 15px;

  svg {
    display: none;
    height: 16px;
  }

  @media (min-width: ${ScreenSize.XS}px) {
    margin-bottom: 25px;
    margin-right: 0px;
  }
`;


const StatusMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  p {
    font-size: 1.4rem;
    line-height: 1.4rem;
  }

  @media (min-width: ${ScreenSize.XS}px) {
    align-items: center;
  }
`;

interface StepItemProps {
  active?: boolean;
}

const StepItem = styled.div<StepItemProps>`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (min-width: ${ScreenSize.XS}px) {
    flex-direction: column;
    width: ${STEP_WIDTH}px;
    z-index: 10;
  }

  & ${StatusBadge} {
    ${({ theme, active }) => active && css`
      background-color: ${theme.orderCancelled ? '#bebebe' : ColorTheme.primaryColor} ;
      
      svg {
        display: block;
        fill: white;
      }
    `}
  }

  & ${StatusMessageContainer} {
    p:first-child {
      margin-bottom: 7px;
      color: #bfbdbd;

      ${({ active }) => active && css`
        font-weight: bold;
        color: unset;
      `}
    }
  }
`;

const StepItemSeparator = styled.div`
  margin-left: 22px;
  position: relative;
  
  div {
    width: 2px;
    height: 30px;
    background-color: #f1f1f1;
  }

  @media (min-width: ${ScreenSize.XS}px) {
    margin-left: 0px;
    margin-top: 22px;
    width: 0px;

    div {
      width: ${STEP_WIDTH}px;
      height: 1px;
      position: absolute;
      left: -75px;
    }
  }
`;


const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${ScreenSize.XS}px) {
    flex-direction: row;
  }
`;

const pickupOrderSteps: OrderStatus[] = [
  "NEW",
  "READY_FOR_PICKUP",
  "PICKED_UP",
];


interface Props {
  deliveryMethod: DeliveryMethod;
  orderCancelled: boolean;
  orderStatus: OrderStatus;
  shippingOrderStatus: ShippingOrderStatus;
  orderDate: Date | null;
  packageDate: Date | null;
  shippingDate: Date | null;
  deliveryDate: Date | null;
}

export default function StatusTimeline(props: Props) {
  const {
    deliveryMethod,
    orderCancelled,
    orderStatus,
    orderDate,
    packageDate,
    shippingDate,
    deliveryDate,
  } = props;

  const dateFormat = 'EEE, LLL d yyyy';

  let pickupActiveStep = 0;

  pickupOrderSteps.forEach((step, index) => {
    if (step === orderStatus) pickupActiveStep = index;
  })

  return (
    <ThemeProvider theme={{ orderCancelled}}>

      {deliveryMethod === "PICKUP_FROM_STORE" && (
        <Container>
          <StepItem active={pickupActiveStep >= 0}>
            <StatusBadge>
              <CheckMarkIcon />
            </StatusBadge>

            <StatusMessageContainer>
              <p>
                <FormattedMessage id="orderPlaced" defaultMessage="Order Placed" />
              </p>
              {orderDate && (
                <p>{formatDate(new Date(orderDate), dateFormat)}</p>
              )}
            </StatusMessageContainer>

          </StepItem>

          <StepItemSeparator>
            <div />
          </StepItemSeparator>

          <StepItem active={pickupActiveStep >= 1}>
            <StatusBadge>
              <CheckMarkIcon />
            </StatusBadge>

            <StatusMessageContainer>
              <p>
                <FormattedMessage id="readyForPickup" defaultMessage="Ready for Pickup" />
              </p>
            </StatusMessageContainer>

          </StepItem>

          <StepItemSeparator>
            <div />
          </StepItemSeparator>

          <StepItem active={pickupActiveStep >= 2}>
            <StatusBadge>
              <CheckMarkIcon />
            </StatusBadge>

            <StatusMessageContainer>
              <p>
                <FormattedMessage id="pickedUp" defaultMessage="Picked Up" />
              </p>
            </StatusMessageContainer>

          </StepItem>

        </Container>
      )}

      {deliveryMethod === "DOOR_DELIVERY" && (
        <Container>
          <StepItem active={!!orderDate}>
            <StatusBadge>
              <CheckMarkIcon />
            </StatusBadge>

            <StatusMessageContainer>
              <p>
                <FormattedMessage id="orderPlaced" defaultMessage="Order Placed" />
              </p>
              {orderDate && (
                <p>{formatDate(new Date(orderDate), dateFormat)}</p>
              )}
            </StatusMessageContainer>

          </StepItem>

          <StepItemSeparator>
            <div />
          </StepItemSeparator>

          <StepItem active={!!packageDate}>
            <StatusBadge>
              <CheckMarkIcon />
            </StatusBadge>

            <StatusMessageContainer>
              <p>
                <FormattedMessage id="packaged" defaultMessage="Packaged" />
              </p>
              {packageDate && (
                <p>{formatDate(new Date(packageDate), dateFormat)}</p>
              )}
            </StatusMessageContainer>

          </StepItem>

          <StepItemSeparator>
            <div />
          </StepItemSeparator>

          <StepItem active={!!shippingDate}>
            <StatusBadge>
              <CheckMarkIcon />
            </StatusBadge>

            <StatusMessageContainer>
              <p>
                <FormattedMessage id="shipped" defaultMessage="Shipped" />
              </p>
              {shippingDate && (
                <p>{formatDate(new Date(shippingDate), dateFormat)}</p>
              )}
            </StatusMessageContainer>

          </StepItem>

          <StepItemSeparator>
            <div />
          </StepItemSeparator>

          <StepItem active={!!deliveryDate}>
            <StatusBadge>
              <CheckMarkIcon />
            </StatusBadge>

            <StatusMessageContainer>
              <p>
                <FormattedMessage id="delivered" defaultMessage="Delivered" />
              </p>
              {deliveryDate && (
                <p>{formatDate(new Date(deliveryDate), dateFormat)}</p>
              )}
            </StatusMessageContainer>

          </StepItem>

        </Container>
      )}
    </ThemeProvider>
  );
}