import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { AppState } from '../../store/RootReducer';
import { resetStoreProductView, triggerFetchProductDetails } from '../../store/ui/StoreProductView/actions';
import { StoreProductViewActionType } from '../../store/ui/StoreProductView/types';
import { StoreProductDetails, ProductVariantProperties } from '../../entities/StoreProductDetails';

import AsyncImage from '../../components/AsyncImage';
import { Button } from '../../components/Button';
import BackButton from '../../components/BackButton';
import ProductImageListItem from './ProductImageListItem';
import QuantityInput from '../../components/form-controls/QuantityInput';
import ProductImagesList from './ProductImagesList';
import { getFormattedPrice, getCurrencyString } from '../../util/currencyUtil';
import StatusBadge from '../StatusBadge';

import {
  Container,
  BackControlContainer,
  CheckoutSection,
  ProductImagesContainer,
  ProductActiveImage,
  ProductImageListItemContainer,
  ProductDataContainer,
  ProductTitleContainer,
  ProductPriceContainer,
  StatusBadgesContainer,
  CategorySection,
  CategoryTitleContainer,
  QuantityCheckoutContainer,
  QuantityContainer,
  CheckoutButtonContainer,
  ProductInfoSectionsContainer,
  SectionHeadersContainer,
  SectionHeader,
  CategorySectionDivider,
  VariantErrorContainer
} from './style';

import DescriptionSection from './DescriptionSection';
import { CartActionType, TriggerAddCartItemPayload } from '../../store/cart/types';
import { triggerAddCartItem, clearAddToCartNotification } from '../../store/cart/actions';
import SkeletonView from './SkeletonView';
import VariantSection from './VariantSection';
import { StoreDetails } from '../../entities/StoreDetails';
import { getPageTitlteSuffix } from '../util';
import usePrevious from '../../hooks/usePrevious';
import SocialShareContent from './SocialShareContent';
import DeliveryOptionsContent from './DeliveryOptionsContent';
import translate from 'translations/utils';
import { FormattedMessage } from 'react-intl';



type ProductSection = 'PRODUCT_DETAILS' | 'SHIPPING_DETAILS';

const productSections: { label: any, value: ProductSection }[] = [
  { label: <FormattedMessage id="productDetails" defaultMessage="Product Details" />, value: 'PRODUCT_DETAILS' },
  // { label: 'Shipping', value: 'SHIPPING_DETAILS' },
];

interface RouteParams {
  storeUrlEndpoint: string;
  productUrlEndpoint: string;
}

interface StoreStateProps {
  storeDetails: StoreDetails;
  productDetails: StoreProductDetails;
  fetchingProductDetails: boolean;
  fetchingProductDetailsError: boolean;
}

interface StoreDispatchProps {
  triggerFetchProductDetails: (productUrlEndpoint: string) => void;
  addCartItem: (payload: TriggerAddCartItemPayload) => void;
  clearAddToCartNotification: () => void;
  resetStoreProductView: () => void;
}

interface OwnProps {

}

type Props = RouteComponentProps<RouteParams> & StoreStateProps & StoreDispatchProps & OwnProps;

function StoreProductView(props: Props) {
  const {
    match,
    history,

    storeDetails,
    productDetails,
    fetchingProductDetails,
    fetchingProductDetailsError,

    triggerFetchProductDetails,
    addCartItem,
    clearAddToCartNotification,
    resetStoreProductView
  } = props;

  const { whiteLabelAcquirer } = storeDetails;

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      resetStoreProductView();
      clearAddToCartNotification();
    };
  }, []);

  const prevFetchingProductDetails = usePrevious(fetchingProductDetails);

  const [quantityValue, setQuantityValue] = useState(1);

  const [activeImageIndex, setActiveImageIndex] = useState(0);

  const [selectedProductVariant, setSelectedProductVariant] = useState<{ name?: string, value?: string }>({});

  const [showVariantError, setShowVariantError] = useState(false);

  const handleVariantSelect = (variantName: string, variantValue: string) => {
    const variant = productDetails.productVariants.find(productVariant => {
      return productVariant.variantValue === variantValue;
    });

    if (!variant) return;

    if (variant.quantity === 0) return;
    
    setSelectedProductVariant({
      name: variantName, value: variantValue
    });

    setQuantityValue(1);
  }


  const handleQuantityOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const numberValue = parseInt(value);

    setQuantityValue(numberValue);
  }

  const handleQuantityIncrement = () => {
    // get available product quantity
    if (!productDetails) return;


    let availableQuantity = productDetails.quantity;

    // if product has variant but no variant has been selected abort
    if (productDetails.hasVariant && !selectedProductVariant.value) {
      return;
    }

    // if product has variant select the appropriate quantity
    if (productDetails.hasVariant) {
      const variant = productDetails.productVariants.find(productVariant => {
        return productVariant.variantValue === selectedProductVariant.value;
      });

      if (!variant) return;

      availableQuantity = variant.quantity;
    }

    if (quantityValue + 1 > availableQuantity) return;

    setQuantityValue(quantityValue + 1);
  }

  const handleQuantityDecrement = () => {
    if (quantityValue - 1 < 1) return;

    setQuantityValue(quantityValue - 1);
  }

  const backButtonHandler = () => {
    const { storeUrlEndpoint } = match.params;
    history.push({ pathname: `/${storeUrlEndpoint}` });
  }

  const addToCartButtonHandler = () => {
    setShowVariantError(true);

    if (productDetails.quantity === 0) return;

    const { id, name, price, currencyCode, productImages, urlEndpoint, hasVariant, productVariants } = productDetails;

    if (hasVariant && selectedProductVariant.name === undefined) return;


    // Pick an imageUrl
    let imageUrl: string | undefined;

    if (productImages.length > 0) {
      imageUrl = productImages[0].fileUrl;
    }

    let productPrice = price;

    if (hasVariant) {
      const selectedVariant = productVariants.find(variant => {
        return variant.variantValue === selectedProductVariant.value;
      }) as ProductVariantProperties;

      productPrice = selectedVariant.price;
    }

    addCartItem({
      productData: {
        id,
        productUrlEndpoint: urlEndpoint,
        name,
        productImageUrl: imageUrl
      },
      quantity: quantityValue,
      currencyCode: currencyCode,
      productPrice: productPrice,
      variantName: selectedProductVariant.name,
      variantValue: selectedProductVariant.value
    });
  }

  useEffect(() => {
    const { productUrlEndpoint } = match.params;
    triggerFetchProductDetails(productUrlEndpoint);
  }, []);

  // Modify page title on successful product details fetch
  useEffect(() => {
    if (prevFetchingProductDetails && !fetchingProductDetails && !fetchingProductDetailsError) {
      const { name: productName } = productDetails;
      const { name } = storeDetails;

      document.title = `${productName} - ${getPageTitlteSuffix(name)}`;
    }
  }, [fetchingProductDetails]);


  if (fetchingProductDetails || fetchingProductDetailsError) {
    return <SkeletonView backButtonHandler={backButtonHandler} />;
  }

  const { productImages, description: productDescription, hasVariant, productVariants } = productDetails;


  let selectedVariantProperties: ProductVariantProperties | undefined;

  if (hasVariant) {
    selectedVariantProperties = productVariants.find(variant => {
      return variant.variantValue === selectedProductVariant.value;
    });
  }


  let productPriceText = "";

  if (hasVariant && !selectedVariantProperties) {
    productPriceText = translate('from', 'From ');
    productPriceText += getFormattedPrice(
      productDetails.price,
      productDetails.currencyCode
    );
  } else if (hasVariant && selectedVariantProperties) {
    productPriceText = getFormattedPrice(
      selectedVariantProperties.price,
      productDetails.currencyCode
    );
  } else {
    productPriceText = getFormattedPrice(
      productDetails.price,
      productDetails.currencyCode
    );
  }

  return (
    <Container>
      <BackControlContainer>
        <BackButton text={translate('backToProducts', 'Back to products')} onClick={backButtonHandler} />
      </BackControlContainer>

      <CheckoutSection>

        <ProductImagesContainer>

          <ProductActiveImage>
            <AsyncImage
              src={productImages[activeImageIndex].fileUrl}
              alt="Product Image"
              imageStyle={{ objectFit: 'contain' }}
            />
          </ProductActiveImage>

          <ProductImagesList>
            {productImages.map((imageProperties, index) => {
              const { fileUrl } = imageProperties;

              return (
                <ProductImageListItemContainer
                  onClick={() => setActiveImageIndex(index)}
                  onMouseOver={() => setActiveImageIndex(index)}
                >
                  <ProductImageListItem imageSrc={fileUrl} active={index === activeImageIndex} />
                </ProductImageListItemContainer>
              );
            })}
          </ProductImagesList>

        </ProductImagesContainer>

        <ProductDataContainer>
          <ProductTitleContainer>
            <h4 style={whiteLabelAcquirer && { color: whiteLabelAcquirer.primaryColor }}>{productDetails.name}</h4>
          </ProductTitleContainer>

          <ProductPriceContainer>
            <h4>
              {productPriceText}
            </h4>
          </ProductPriceContainer>

          <StatusBadgesContainer>
            {productDetails.quantity === 0 && (
              <StatusBadge color="RED" text={translate('soldOut', 'SOLD OUT')} />
            )}
          </StatusBadgesContainer>

          <VariantSection
            activeVariantValue={selectedProductVariant.value}
            productVariants={productVariants}
            setActiveVariant={handleVariantSelect}
          />

          {showVariantError && hasVariant && selectedProductVariant.name === undefined && (
            <VariantErrorContainer>
              <p>
                <FormattedMessage id="selectProductVariant" defaultMessage="Please select a product variant" />
              </p>
            </VariantErrorContainer>
          )}

          <CategorySection>
            <CategoryTitleContainer>
              <h6>
                <FormattedMessage id="quantityBig" defaultMessage="Quantity" />
              </h6>
            </CategoryTitleContainer>

            <QuantityCheckoutContainer>

              <QuantityContainer>
                <QuantityInput
                  value={quantityValue}
                  onChange={handleQuantityOnChange}
                  onIncrement={handleQuantityIncrement}
                  onDecrement={handleQuantityDecrement}
                  containerStyle={{ width: '100%' }}

                />
              </QuantityContainer>

              <CheckoutButtonContainer>
                <Button
                  text={<FormattedMessage id="addToCart" defaultMessage="Add To Cart" />}
                  color="PRIMARY"
                  onClick={addToCartButtonHandler}
                  containerStyle={{ width: '100%' }} />
              </CheckoutButtonContainer>

            </QuantityCheckoutContainer>

          </CategorySection>


          {(storeDetails.pickupAvailable || storeDetails.shippingAvailable) && (
            <>
              <CategorySectionDivider />

              <CategorySection>
                <DeliveryOptionsContent
                  pickupAvailable={storeDetails.pickupAvailable}
                  shippingAvailable={storeDetails.shippingAvailable}
                />
              </CategorySection>
            </>
          )}


          {/* <CategorySection>
            <CategoryTitleContainer>
              <h6>Share this item with friends</h6>
            </CategoryTitleContainer>

            <SocialShareContent />

          </CategorySection> */}

        </ProductDataContainer>

      </CheckoutSection>


      <ProductInfoSectionsContainer>

        {whiteLabelAcquirer && (
          <SectionHeadersContainer>
            {productSections.map(section => {
              const { label } = section;

              return (
                <SectionHeader whiteLabelColorConfig={{
                  primaryColor: whiteLabelAcquirer.primaryColor,
                  secondaryColor: whiteLabelAcquirer.secondaryColor
                }}>
                  <h4 style={whiteLabelAcquirer && { color: whiteLabelAcquirer.primaryColor}}>{label}</h4>
                </SectionHeader>
              );
            })}
          </SectionHeadersContainer>
        )}

        <div>
          <DescriptionSection content={productDescription} />
        </div>

      </ProductInfoSectionsContainer>

    </Container>
  );
}


const mapStateToProps = (state: AppState): StoreStateProps => ({
  storeDetails: state.store.storeDetails as StoreDetails,
  productDetails: state.ui.storeProductView.productDetails as StoreProductDetails,
  fetchingProductDetails: state.ui.storeProductView.fetchingProductDetails,
  fetchingProductDetailsError: state.ui.storeProductView.fetchingProductDetailsError
});

const mapDispatchToProps = (dispath: (action: StoreProductViewActionType | CartActionType) => void): StoreDispatchProps => ({
  triggerFetchProductDetails(productUrlEndpoint: string) {
    dispath(triggerFetchProductDetails(productUrlEndpoint));
  },
  addCartItem(payload: TriggerAddCartItemPayload) {
    dispath(triggerAddCartItem(payload));
  },
  clearAddToCartNotification() {
    dispath(clearAddToCartNotification());
  },
  resetStoreProductView() {
    dispath(resetStoreProductView());
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StoreProductView));
