import React, { ReactNode, CSSProperties } from "react";
import styled from "styled-components";

import IconButton from "../../components/IconButton";
import IconImage from "../../assets/icons/shopping-cart.svg";

const Container = styled.div`
  position: relative;
  cursor: pointer;
`;

const CountContainer = styled.div`
  padding: 0px 4px;
  box-sizing: border-box;
  min-width: 20px;
  height: 20px;
  background: #ff2121;
  position: absolute;
  left: 15px;
  top: -5px;
  border-radius: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  p {
    color: white;
    font-size: 11px;
  }
`;

interface Props {
  itemCount: number;
  onClickHandler: () => void;
}

export default function ControlContent(props: Props) {
  const { itemCount, onClickHandler } = props;

  return (
    <Container onClick={onClickHandler}>

      {itemCount > 0 && (
        <CountContainer>
          <p>{itemCount}</p>
        </CountContainer>
      )}

      <IconButton size="M" image={<img src={IconImage} alt='Medium' />} onClickHandler={() => { }} />

    </Container>
  );
}