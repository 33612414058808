import React from 'react';
import styled from "styled-components";
import NotificationWidget from '../../components/NotificationWidget';
import { ScreenSize } from '../../styling/constants';
import translate from 'translations/utils';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  
  @media (min-width: ${ScreenSize.XS}px) {

  }
`;


interface Props {
  actionHandler: () => void;
}

export default function FetchingOrderDetailsErrorView(props: Props) {
  const { actionHandler } = props;

  return (
    <Container>
      <NotificationWidget
        type="ERROR"
        title={translate('somethingWentWrong', 'Something went wrong!')}
        message={translate('somethingWentWrongDesc2', "We couldn't get the order's details. Please refresh below.")}
        action={actionHandler}
        actionText={"Refresh"}
      />
    </Container>
  );
}